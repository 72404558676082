import { useCallback, useState } from 'react'
import classNames from 'classnames'
import { ShareTooltip } from '../shareTooltip'

import styles from './shareButton.module.scss'

export const ShareButton = ({ href = '', title = '', hashtags = [], metricsText = '' }) => {
    const [showShare, setShowShare] = useState(false)

    const onTooltipClose = useCallback(() => {
        setShowShare(false)
    }, [])

    return (
        <ShareTooltip
            pageLink={href}
            name={title}
            hashtags={hashtags}
            metricsText={metricsText}
            forceShow={showShare}
            onClose={onTooltipClose}
        >
            <button
                className={styles.shareButton}
                onClick={() => {
                    setShowShare(true)
                }}
            >
                <span>Share</span>
                <span className={classNames(`icon icon-share`, styles.icon)} />
            </button>
        </ShareTooltip>
    )
}
