import Image from 'next/image'
import { useState } from 'react'
import styles from './expandableVerifiedTag.module.scss'
import classNames from 'classnames'
import { useRouter } from 'next/router'

export const ExpandableVerifiedTag = ({ size = 24, expandable = true }) => {
    const [isExtended, setIsExtended] = useState(false)
    const router = useRouter()
    return (
        <div
            className={classNames(styles.imageContainer, {
                [styles.expandable]: expandable,
            })}
        >
            <Image src={`/static/svg/vsp-tag.svg`} alt={`Verified Provider`} width={size} height={size} priority />
            {expandable && (
                <Image
                    className={classNames(styles.extendedImage, {
                        [styles.isExtended]: isExtended,
                    })}
                    src={'/static/svg/vsp-tag-extended.svg'}
                    alt={`Verified`}
                    width={72 * (size / 24)}
                    height={size}
                    onMouseOver={() => setIsExtended(true)}
                    onMouseOut={() => setIsExtended(false)}
                    onClick={() => {
                        if (isExtended) {
                            router.push('/vsp')
                        }
                    }}
                />
            )}
        </div>
    )
}
