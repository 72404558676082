import { useState, useRef } from 'react'

import styles from './bgVideo.module.scss'

export function BgVideo({ vimeoId = '831202687?h=925e7bb733', title = '', className = '', style = {} }) {
    const [videoLoaded, setVideoLoaded] = useState(false)
    const videoRef = useRef(null)

    return (
        <div className={[styles.video, className].join(' ')} data-loaded={videoLoaded} style={style}>
            <iframe
                ref={videoRef}
                onLoad={() => setVideoLoaded(true)}
                src={`https://player.vimeo.com/video/${vimeoId}?badge=0&autopause=0&player_id=0&app_id=58479&background=1&playsinline=1&portrait=1`}
                frameBorder='0'
                allow='autoplay;'
                allowFullScreen
                title={title}
                width='100%'
                height='100%'
            />
        </div>
    )
}
