import classNames from 'classnames'
import { assetSectionsIds } from 'components/header/constants/assetSections'
import { useNavigationContext } from 'contexts/navigationContext'

export function PageSection({ sectionId, children }) {
    const { selectedSectionId } = useNavigationContext()

    const isSelectedCalculatorSectionId = selectedSectionId === assetSectionsIds.calculator

    const isCalculatorSectionId = sectionId === assetSectionsIds.calculator

    const isShow = sectionId === selectedSectionId

    // we need to show the calculator section only if it is selected
    // and don't render all other sections
    if (isSelectedCalculatorSectionId && !isCalculatorSectionId) {
        return null
    }

    if (!isSelectedCalculatorSectionId && isCalculatorSectionId) {
        return null
    }

    return <div className={classNames('flex-col gap-12', isShow ? 'flex' : 'hidden')}>{children}</div>
}
