import classNames from 'classnames'
import { useTimeElapsed } from 'hooks'
import {
    getMetricValueByKey,
    getMetricAbsoluteChange,
    getMetricChangePercentage,
    getMetricPrecision,
} from 'utils/actions'
import { DEFAULT_ASSET_TIMEFRAME } from 'utils/constants'
import { isInvalidNumber, formatOutputNumber } from 'utils/formatter'
import { TooltipOnHover } from 'components/ui'
import { withTooltip } from 'components/ui/tooltip'
import { withLoadingSkeleton } from 'components/ui/skeleton'
import { getPercentageTextColorClassNames, getValueTextColorClassNames } from './utils'

import styles from './profileMetric.module.scss'

const LabelWithTooltip = withTooltip(({ label = '' }) => {
    return (
        <div className={styles.labelWrap}>
            <span className={styles.label}>{label ?? ''}</span>
        </div>
    )
})

const ProfileMetricComponent = ({ metricGroup = null, item = null, timeframeKey = DEFAULT_ASSET_TIMEFRAME.key }) => {
    const value = getMetricValueByKey(item, metricGroup?.key, metricGroup?.timeframeKey)
    const percentage =
        metricGroup?.withPercentage && !isInvalidNumber(value)
            ? metricGroup?.postfix === '%'
                ? getMetricAbsoluteChange(item?.metrics, metricGroup?.key, timeframeKey)
                : getMetricChangePercentage(item?.metrics, metricGroup?.key, timeframeKey)
            : undefined

    const updatedTimeStatus = useTimeElapsed(
        item?.metrics?.find(metric => metric?.metricKey === metricGroup?.key)?.createdAt
    )

    const valueColorClassName = getValueTextColorClassNames(
        value,
        metricGroup?.withColor && !metricGroup?.withPercentage,
        metricGroup?.key
    )
    const percentageColorClassName = getPercentageTextColorClassNames(percentage, metricGroup?.withColor)

    return (
        <div className='flex flex-col gap-y-2'>
            <LabelWithTooltip
                label={metricGroup?.label ?? ''}
                tooltipObject={{
                    ...metricGroup?.tooltipTextObj,
                }}
                tooltipClassName={styles.tooltip}
                updatedTimeStatus={updatedTimeStatus}
            />
            <div className='flex flex-row gap-x-2 items-center'>
                <p className={classNames('font-bold text-[24px]', valueColorClassName)}>
                    {formatOutputNumber(value, {
                        // Show all the decimals for the price metric
                        precision: getMetricPrecision(metricGroup?.key, value),
                        forcePrecision: false,
                        allowEmpty: false,
                        withAbbreviation: metricGroup?.key !== 'price',
                        showApproximation: true,
                        prefix: metricGroup?.prefix ?? '',
                        postfix: metricGroup?.postfix ?? '',
                        showPlus: Boolean(metricGroup?.showPlus),
                    })}
                </p>
                {!isInvalidNumber(percentage) && (
                    <TooltipOnHover
                        text={`${timeframeKey} ${
                            metricGroup?.postfix === '%' ? 'Absolute Change' : 'Change Percentage'
                        }`}
                        textClassName={'!text-[10px] !whitespace-normal !text-center !w-[80px] !px-2 !py-1'}
                    >
                        <p className={classNames(' text-[18px] font-bold', percentageColorClassName)}>
                            {formatOutputNumber(percentage, {
                                precision: 2,
                                forcePrecision: false,
                                allowEmpty: false,
                                withAbbreviation: true,
                                showApproximation: true,
                                postfix: '%',
                                spaceAfterNumber: false,
                                showPlus: true,
                            })}
                        </p>
                    </TooltipOnHover>
                )}
            </div>
        </div>
    )
}

export const ProfileMetric = withLoadingSkeleton(ProfileMetricComponent)
