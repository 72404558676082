import { memo } from 'react'
import classNames from 'classnames'

import styles from './toggleButton.module.scss'

export const ToggleButtonComponent = ({ expanded = false, hidden = false, onClick = () => {}, className }) => {
    return (
        <div
            className={classNames(
                styles.toggleButton,
                {
                    [styles.hidden]: hidden,
                },
                className
            )}
            onClick={() => {
                if (!hidden) {
                    onClick()
                }
            }}
        >
            <div
                className={classNames(styles.toggle, {
                    [styles.expanded]: expanded,
                })}
            >
                <span className={styles.bar1} />
                <span className={styles.bar2} />
            </div>
        </div>
    )
}

export const ToggleButton = memo(ToggleButtonComponent)
