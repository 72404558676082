import Image from 'next/image'
import { useRouter } from 'next/router'

import styles from './assetStack.module.scss'

export const AssetStack = ({ items = [], iconSize = 18, overlap = 5 }) => {
    const router = useRouter()
    return (
        <div className={styles.iconStack} style={{ marginLeft: `${overlap}px` }}>
            {items?.filter(Boolean)?.map((item, idx) => {
                const iconUrl = item?.logoUrl ?? ''
                return (
                    <div
                        key={`icon-${iconUrl}-${idx}`}
                        className={styles.imgWrap}
                        style={{
                            marginLeft: `-${overlap}px`,
                            width: `${iconSize}px`,
                            height: `${iconSize}px`,
                        }}
                    >
                        <Image
                            src={iconUrl}
                            className={styles.icon}
                            style={{ '--size': `${iconSize}px` }}
                            alt={'Icon'}
                            width={iconSize}
                            height={iconSize}
                            onClick={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                router.push(`/asset/${item?.slug}`)
                            }}
                        />
                    </div>
                )
            })}
        </div>
    )
}
