import { Table } from 'components/tables/table'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import dynamic from 'next/dynamic'
import { BITCOIN_AND_OTHERS_KEY, PageSizes, STABLECOIN_KEY } from 'utils/constants'
import { useAssetsData } from './hooks'
import { getNetStakingFlowItem, reorderArrayBySlug } from './utils'

import { useQuery } from '@tanstack/react-query'
import { Skeleton, TableView } from 'components/ui'
import { fetchProviderCountForAssets, fetchRewardOptionsForAssets, getSelectedAssetsHopePage } from 'data/queries'
import { useMemo } from 'react'
import styles from './assetsTable.module.scss'

const AssetTableRow = dynamic(() => import('./assetTableRow').then(mod => mod.AssetTableRow), {
    ssr: false,
})

const AssetCard = dynamic(() => import('./assetCard').then(mod => mod.AssetCard), {
    ssr: false,
})

export const ArchiveTable = () => {
    const { data, isLoading, noDataFound, isEnd } = useAssetsData()
    const { queryParams, selectedTableView } = useTableArchiveContext()

    const isLoadingData = !data && isLoading

    let metricsItems = []
    if (!isLoadingData && data?.length) {
        const allItems = [...data]
        if (Boolean(allItems?.length)) {
            metricsItems = [...allItems.flatMap(item => item?.assetsWithMetrics ?? [])]
        }
    }

    const assetSlugs = data?.[0]?.assets?.map(a => a?.slug)

    const isStablecoins = queryParams?.category?.key === STABLECOIN_KEY
    const isBtc = queryParams?.category?.key === BITCOIN_AND_OTHERS_KEY

    // Fetch provider counts for assets
    const {
        data: providerCounts = {},
        isLoading: isLoadingProviderCounts,
        error: providerCountsError,
    } = useQuery({
        queryKey: ['providerCounts', assetSlugs],
        queryFn: async () => {
            if (!assetSlugs) return {}
            const providerCounts = await fetchProviderCountForAssets(assetSlugs)
            return Object.entries(providerCounts || {}).reduce((acc, [key, value]) => {
                acc[key.replaceAll('__', '-')] = value?.providers?.count
                return acc
            }, {})
        },
        enabled: Boolean(assetSlugs && (isStablecoins || isBtc)),
    })

    const {
        data: stablecoinData = [],
        isLoading: isLoadingStablecoinData,
        error: stablecoinDataError,
    } = useQuery({
        queryKey: ['stablecoinData', assetSlugs],
        queryFn: async () => {
            if (!assetSlugs) return []
            const result = await fetchRewardOptionsForAssets(assetSlugs)
            return getRewardRates(result)
        },
        enabled: Boolean(assetSlugs && (isStablecoins || isBtc)),
    })
    const isGridView = selectedTableView === TableView.Grid
    return (
        <>
            <Table
                data={data}
                isLoading={isLoading}
                noDataFound={noDataFound}
                isEnd={isEnd}
                getItemsFromData={data => {
                    const allItems = [...data]
                    if (Boolean(allItems?.length)) {
                        return [...allItems.flatMap(item => item?.assets ?? [])]
                    }
                    return []
                }}
                renderItemComponent={(isGrid, params) => {
                    const ItemComponent = isGrid ? AssetCard : AssetTableRow
                    return (
                        <div className='flex flex-col gap-4'>
                            <ItemComponent
                                {...params}
                                netStakingFlowItem={getNetStakingFlowItem(metricsItems, params?.item)}
                                providerCount={providerCounts?.[params?.item?.slug]}
                                stablecoinData={stablecoinData?.[params?.item?.slug]}
                            />
                        </div>
                    )
                }}
            />
            {isLoading && (
                <div className='flex flex-col gap-5 mt-5'>
                    {Array.from({ length: 20 }, (_, idx) => {
                        return (
                            <Skeleton
                                key={`item-${idx}`}
                                width='100%'
                                height={isGridView ? '350px' : '80px'}
                                borderRadius={8}
                            />
                        )
                    })}
                </div>
            )}
        </>
    )
}

export const ListAssetsHomePage = () => {
    const { selectedTableView, paginationParams, setHasMore, queryParams } = useTableArchiveContext()

    // Get 7 (+1) days for the bar chart
    const weekAgoDate = useMemo(() => {
        return new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
    }, [])

    const orderList = [
        'bitcoin',
        'ethereum-2-0',
        'solana',
        'staked-ether',
        'usd-coin',
        'starknet',
        'near-protocol',
        'polkadot',
        'cosmos',
        'tether',
    ]

    const {
        data = [],
        isLoading,
        error,
    } = useQuery({
        queryKey: ['selectedAssets'],
        queryFn: async () => {
            const response = await getSelectedAssetsHopePage({
                timeframe: '7d',
                weekAgoDate: weekAgoDate,
            })
            return [response]
        },
        enabled: true,
    })

    let sortedData = data

    if (data?.[0]) {
        const updatedData = {
            ...data[0],
            assets: reorderArrayBySlug(data[0]?.assets, orderList),
            assetsWithMetrics: reorderArrayBySlug(data[0]?.assetsWithMetrics, orderList),
        }
        sortedData = [updatedData, ...data.slice(1)]
    }

    const pageSizeNumber = Number(paginationParams?.pageSize?.key ?? PageSizes.Twenty)

    let rowItems = []
    let metricsItems = []
    if (isLoading) {
        rowItems = [...Array(pageSizeNumber).keys()]
    } else if (sortedData?.length) {
        const allItems = [...sortedData]
        if (Boolean(allItems?.length)) {
            rowItems = [...allItems.flatMap(item => item?.assets ?? [])]
            metricsItems = [...allItems.flatMap(item => item?.assetsWithMetrics ?? [])]
        }
    }

    const assetSlugs = rowItems?.map(a => a?.slug) ?? []

    const { data: providerCounts = {} } = useQuery({
        queryKey: ['providerCounts', assetSlugs],
        queryFn: async () => {
            const providerCounts = await fetchProviderCountForAssets(assetSlugs)
            return Object.entries(providerCounts || {}).reduce((acc, [key, value]) => {
                acc[key.replaceAll('__', '-')] = value?.providers?.count
                return acc
            }, {})
        },
        enabled: assetSlugs.length > 0,
    })

    const { data: stablecoinData = [] } = useQuery({
        queryKey: ['stablecoinData', assetSlugs],
        queryFn: async () => {
            const result = await fetchRewardOptionsForAssets(assetSlugs)
            return getRewardRates(result)
        },
        enabled: assetSlugs.length > 0,
    })

    if (!isLoading && data?.length <= 0) {
        return (
            <div className='mt-[20px]'>
                <p>There are no results for your search. Please revise your filters.</p>
            </div>
        )
    }

    const categoryMapping = {
        bitcoin: 'bitcoin-and-others',
        tether: 'stablecoin',
        'usd-coin': 'stablecoin',
        'ethereum-2-0': 'proof-of-stake',
        solana: 'proof-of-stake',
        polkadot: 'proof-of-stake',
        'near-protocol': 'proof-of-stake',
        'staked-ether': 'liquid-staking',
        starknet: 'proof-of-stake',
        cosmos: 'proof-of-stake',
    }

    rowItems?.forEach(item => {
        if (item?.slug && categoryMapping[item.slug]) {
            item.category = categoryMapping[item.slug]
        }
    })

    return (
        <>
            {rowItems.map((item, idx) => {
                const key = `item-${item?.slug ?? 'loading'}-${idx + 1}`
                const netStakingFlowItem = getNetStakingFlowItem(metricsItems, item)

                return (
                    <div className='mb-2' key={key}>
                        {selectedTableView === TableView.Grid ? (
                            <AssetCard
                                index={idx + 1}
                                item={isLoading ? null : item}
                                isLoading={isLoading}
                                netStakingFlowItem={netStakingFlowItem}
                                minimal={false}
                                noExpansion={true}
                                category={item?.category}
                                stablecoinData={stablecoinData?.[item?.slug]}
                            />
                        ) : (
                            <AssetTableRow
                                index={idx + 1}
                                item={isLoading ? null : item}
                                isLoading={isLoading}
                                netStakingFlowItem={netStakingFlowItem}
                                minimal={false}
                                noExpansion={true}
                                category={item?.category}
                                providerCount={providerCounts?.[item?.slug]}
                                stablecoinData={stablecoinData?.[item?.slug]}
                            />
                        )}
                    </div>
                )
            })}
        </>
    )
}

export const CardsOnlyAssetsTable = () => {
    const { paginationParams } = useTableArchiveContext()

    const pageSizeNumber = Number(paginationParams?.pageSize?.key ?? PageSizes.Twenty)

    const { data, isLoading, noDataFound } = useAssetsData()

    const startingIndex = 0

    const isLoadingData = !data && isLoading

    let rowItems = []
    let metricsItems = []
    if (isLoadingData) {
        rowItems = [...Array(pageSizeNumber).keys()]
    } else if (data?.length) {
        const allItems = [...data]
        if (Boolean(allItems?.length)) {
            rowItems = [...allItems.flatMap(item => item?.assets ?? [])]
            metricsItems = [...allItems.flatMap(item => item?.assetsWithMetrics ?? [])]
        }
    }

    if (!isLoading && noDataFound) {
        return (
            <div className='mt-[20px]'>
                <p>There are no results for your search. Please revise your filters.</p>
            </div>
        )
    }

    return (
        <div className={styles.grid}>
            {rowItems?.map((item, idx) => (
                <AssetCard
                    key={`item-${item?.slug ?? ''}-${startingIndex + idx + 1}`}
                    index={startingIndex + idx + 1}
                    item={isLoadingData ? null : item}
                    isLoading={isLoadingData}
                    netStakingFlowItem={getNetStakingFlowItem(metricsItems, item)}
                    minimal={false}
                />
            ))}
        </div>
    )
}

function getRewardRates(data) {
    const rewardRates = {}

    // Iterate over each reward option in the data
    for (const [rewardOption, details] of Object.entries(data)) {
        let bestRate = null
        let worstRate = null
        const providers = []

        // Iterate over each entry in the reward option array
        details.forEach(entry => {
            if (entry.metrics) {
                entry.metrics.forEach(metric => {
                    if (metric.metricKey === 'reward_rate') {
                        const rate = metric.defaultValue
                        if (bestRate === null || rate > bestRate) {
                            bestRate = rate
                        }
                        if (worstRate === null || rate < worstRate) {
                            worstRate = rate
                        }
                    }
                })
            }
            if (entry?.providers && providers?.length < 5 && !providers.find(p => p.id === entry.providers[0].id)) {
                providers.push(...entry.providers)
            }
        })

        const formattedKey = rewardOption.replaceAll('__', '-')
        // Store the best and worst reward rates for the current reward option
        rewardRates[formattedKey] = {
            bestRate: bestRate,
            worstRate: worstRate,
            providers,
        }
    }

    return rewardRates
}
