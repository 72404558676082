import classNames from 'classnames'

export function OptionButton({ text, onClick, disabled, className = '', textClassName = '', isContrast = false }) {
    return (
        <button
            type='button'
            className={classNames(
                'relative flex items-center min-w-[240px] h-[40px] px-4 py-3 rounded-lg border-solid hover:border-primary dark:hover:border-ultra-light border border-transparent duration-300 cursor-pointer transition-all bg-transparent',
                className,
                {
                    '!cursor-not-allowed': disabled,
                }
            )}
            onClick={onClick}
            disabled={disabled}
        >
            <div
                className={classNames(
                    'absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-primary/10 transition-all duration-300 delay-100 opacity-0 hover:opacity-100 z-10',
                    {
                        'to-red/10': disabled && !isContrast,
                        '!text-contrast-1': disabled && isContrast,
                    }
                )}
            ></div>
            <span
                className={classNames('text-sm font-bold text-contrast-6', textClassName, {
                    'text-contrast-1': disabled,
                })}
            >
                {disabled ? `${text} (Coming Soon)` : text}
            </span>
        </button>
    )
}
