import classNames from 'classnames'

import styles from './title.module.scss'

export const Title = ({ className = '', children }) => {
    return (
        <h1 className={classNames(styles.title, className)} data-cy='title'>
            {children}
        </h1>
    )
}
