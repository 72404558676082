import { gql } from 'graphql-request'
import { getBaseUrl } from 'utils/api'
import { FETCH_TIMEOUT_MS } from './queries'

export async function fetchCmsContent(query = '', variables = {}) {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => {
        controller.abort()
    }, FETCH_TIMEOUT_MS)

    try {
        const response = await fetch(`${getBaseUrl()}/api/cmsQuery`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query,
                variables,
            }),
            signal: controller.signal,
        })

        const result = await response?.json()

        if (response.ok) {
            return result?.data ?? ''
        } else {
            throw new Error(`Something went wrong: ${response?.statusText}`)
        }
    } catch (err) {
        console.error(`Fetch error: ${err.message}`)
        // throw err
    } finally {
        clearTimeout(timeoutId)
    }

    return ''
}

export const calculatorTextQuery = gql`
    {
        page(id: "cGFnZToxMTAzNw==") {
            id
            slug
            content
        }
    }
`

export const vspQuery = gql`
    {
        pageBy(uri: "vsp") {
            vsp {
                vsps {
                    sourceUrl
                    title
                }
                globalLinks {
                    getVerifiedUrl
                    viewAllUrl
                }
                heroStats {
                    first {
                        label
                        value
                        changePercentage
                    }
                    second {
                        label
                        value
                        changePercentage
                    }
                }
                features {
                    first {
                        title
                        content
                    }
                    second {
                        title
                        content
                    }
                    third {
                        title
                        content
                    }
                }
                stakingMadeEasy {
                    title
                    content
                }
                makeStakingSafe {
                    title
                    content
                }
                industryStandard {
                    title
                    content
                }
                srRating {
                    title
                    subtitle
                    features {
                        feature
                    }
                    content
                    learnMoreUrl
                }
                valueAddedRating {
                    title
                    subtitle
                    features {
                        feature
                    }
                    content
                    learnMoreUrl
                }
                dueDiligence {
                    title
                    content
                }
                monitoring {
                    title
                    content
                }
                trustedBy {
                    sourceUrl
                    title
                    media_options {
                        webUrl
                    }
                }
                marketLeading {
                    title
                    content
                    learnMoreUrl
                }
                testimonials {
                    testimonial {
                        person {
                            sourceUrl
                            title
                            media_options {
                                role
                            }
                        }
                        content
                    }
                }
                closing {
                    title
                    content
                }
            }
        }
    }
`

const getPostsQuery = (whereString = '', limit = 10) => {
    return gql`{
        posts(where: { ${whereString} }, last: ${limit}) {
            nodes {
                id
                title
                slug
                excerpt
                date
                content
                categories {
                    nodes {
                        name
                        slug
                    }
                }
                post_options {
                    featuredImage {
                        mimeType
                        sourceUrl(size: MEDIUM_LARGE)
                        mediaDetails {
                            width
                            height
                        }
                    }
                }
            }
        }
    }`
}

// used in sitemap generation
export const fetchJournalSlugs = async () => {
    return await fetch('https://content.stakingrewards.com/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.STRAPI_TOKEN}`,
        },
        body: JSON.stringify({
            query: gql`
                {
                    posts(sort: "date:DESC", pagination: { start: 0, limit: 999 }) {
                        data {
                            attributes {
                                slug
                                categories {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
        }),
    })
}

export const fetchAnnouncements = async (limit = 30) => {
    return await fetchCmsContent(getPostsQuery(`categoryName: "announcement"`, limit))
}

export const getPageContent = async uri => {
    const query = gql`
        query GetPage($uri: String) {
            page: pageBy(uri: $uri) {
                title
                slug
                content
            }
        }
    `
    return await fetchCmsContent(query, { uri })
}

const staticPageIdMap = {
    'terms-and-conditions': 1,
    'risk-disclosures': 2,
    'aml-policy': 3,
}

export const fetchStrapiContent = async (filterStr = '', start = 0, limit = 5) => {
    return await fetch(`${getBaseUrl()}/api/journal`, {
        method: 'POST',
        body: JSON.stringify({
            query: gql`
                {
                    posts(sort: "date:DESC", pagination: { start: ${start}, limit: ${limit} }, filters: { ${filterStr} }) {
                        data {
                            attributes {
                                title
                                slug
                                date
                                categories {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                tags {
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                                authors {
                                    data {
                                        attributes {
                                            nickName
                                            biographicalInfo
                                            username            
                                            
                                        }
                                    }
                                }
                                featuredImage {
                                    data {
                                        attributes {
                                            width
                                            height
                                            url
                                        }
                                    }
                                }
                                infographic {
                                    data {
                                        attributes {
                                            width
                                            height
                                            url
                                        }
                                    }
                                }
                                tldr
                                excerpt
                                content
                                createdAt
                                updatedAt
                            }
                        }
                    }
                }`,
        }),
    })
}

export const getPageContentFromStrapi = async title => {
    // TODO change journal to generic
    return await fetch(`${getBaseUrl()}/api/journal`, {
        method: 'POST',
        body: JSON.stringify({
            query: gql`
                {
                    staticPages(filters: {id: {eq: ${staticPageIdMap[title]}}}) {
                        data {
                            attributes {
                                Headline
                                Content
                            }
                        }
                    }
                }
            `,
        }),
    })
}
