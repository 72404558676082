import { calculateIfValid, getMetricPercentage, getMetricPrecision, getMetricValueByKey } from 'utils/actions'
import { DEFAULT_ASSET_TIMEFRAME, STAKING_RISK_RATINGS } from 'utils/constants'
import { NO_DATA_INDICATOR, formatOutputNumber, isInvalidNumber } from 'utils/formatter'

export const getFormattedMetricValue = (value = null, metricGroup = null) => {
    let formattedValue = value
    if (metricGroup?.key === 'staking_risk_rating') {
        formattedValue = STAKING_RISK_RATINGS.find(r => r?.key === String(value))?.letter ?? NO_DATA_INDICATOR
    } else {
        formattedValue = formatOutputNumber(value, {
            precision: getMetricPrecision(metricGroup?.key, value),
            forcePrecision: false,
            allowEmpty: false,
            withAbbreviation: metricGroup?.key !== 'price',
            showApproximation: true,
            prefix: metricGroup?.prefix ?? '',
            postfix: metricGroup?.postfix ?? '',
            showPlus: Boolean(metricGroup?.showPlus),
        })
    }
    return formattedValue
}

export const getValueTextColorClassNames = (value = null, hasColor = false, metricKey = '') => {
    let valueColorClassName = 'text-contrast-4'
    if (hasColor && !isInvalidNumber(value)) {
        if (metricKey === 'peg_deviation') {
            if (value < 98) {
                valueColorClassName = 'text-red'
            } else if (value < 99.5) {
                valueColorClassName = 'text-orange'
            } else if (value < 102) {
                valueColorClassName = 'text-green'
            } else if (value < 104) {
                valueColorClassName = 'text-orange'
            } else {
                valueColorClassName = 'text-red'
            }
        } else if (metricKey === 'peg_stability') {
            if (value >= 99) {
                valueColorClassName = 'text-green'
            } else if (value >= 98) {
                valueColorClassName = 'text-yellow'
            } else if (value >= 95) {
                valueColorClassName = 'text-red'
            } else {
                valueColorClassName = 'text-magenta'
            }
        } else if (metricKey === 'vsp_score') {
            if (value >= 70) {
                valueColorClassName = 'text-green'
            } else if (value >= 50) {
                valueColorClassName = 'text-yellow'
            } else if (value >= 25) {
                valueColorClassName = 'text-orange'
            } else {
                valueColorClassName = 'text-red'
            }
        } else {
            if (value > 0) {
                valueColorClassName = 'text-green'
            } else if (value < 0) {
                valueColorClassName = 'text-red'
            }
        }
    }

    return valueColorClassName
}

export const getPercentageTextColorClassNames = (percentage = null, hasColor = false) => {
    let percentageColorClassName = 'text-contrast-3'
    if (hasColor && !isInvalidNumber(percentage)) {
        if (percentage > 0) {
            percentageColorClassName = 'text-green'
        } else if (percentage < 0) {
            percentageColorClassName = 'text-red'
        }
    }
    return percentageColorClassName
}

export const checkInverseMetricOrder = (metricKey = '') => {
    return ['inflation_rate', 'staking_risk_rating'].includes(metricKey)
}

export const getMarketPosition = (
    currentMetricKey = '',
    itemMarketValue = null,
    minMarketValue = null,
    maxMarketValue = null
) => {
    return calculateIfValid(
        ({ minMarketValue, maxMarketValue, itemMarketValue }) => {
            let normalizedValue
            if (currentMetricKey === 'staking_risk_rating') {
                // From 1 to 5
                const clampedValue = Math.min(Math.max(itemMarketValue, 1), 5)
                normalizedValue = (clampedValue - 1) / (5 - 1)
            } else if (['reward_stability_365d', 'staking_ratio'].includes(currentMetricKey)) {
                const clampedValue = Math.min(Math.max(itemMarketValue, 0), 100)
                normalizedValue = (clampedValue - 0) / (100 - 0)
            } else {
                const minMarketLogValue = Math.log(
                    minMarketValue <= 0 ? minMarketValue + Math.abs(minMarketValue) + 1 : minMarketValue
                )

                const maxMarketLogValue = Math.log(
                    minMarketValue <= 0 ? maxMarketValue + Math.abs(minMarketValue) + 1 : maxMarketValue
                )

                const itemMarketLogValue = Math.log(
                    minMarketValue <= 0 ? itemMarketValue + Math.abs(minMarketValue) + 1 : itemMarketValue
                )
                normalizedValue = (itemMarketLogValue - minMarketLogValue) / (maxMarketLogValue - minMarketLogValue)
            }

            const isInverse = checkInverseMetricOrder(currentMetricKey)

            return isInverse ? 1 - normalizedValue : normalizedValue
        },
        {
            minMarketValue,
            maxMarketValue,
            itemMarketValue,
        }
    )
}

export const getValueOrPercentage = (item = null, byChange = false, metricGroup = null, timeframeKey = '') => {
    if (byChange) {
        const unit = metricGroup?.prefix || metricGroup?.postfix
        return metricGroup?.withPercentage ? getMetricPercentage(item, metricGroup?.key, timeframeKey, unit) : null
    }
    return getMetricValueByKey(item, metricGroup?.key, metricGroup?.timeframeKey)
}

export const getUniquePerformers = (
    allPerformers = [],
    item = null,
    metricGroup = null,
    byChange = false,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    order = 'asc'
) => {
    const uniquePerformerKeys = Array.from(
        new Set(allPerformers.map(p => p?.slug).filter(slug => slug && slug !== item?.slug))
    )

    return [
        ...allPerformers.filter(
            p =>
                uniquePerformerKeys?.includes(p?.slug) &&
                !isInvalidNumber(getValueOrPercentage(p, byChange, metricGroup, timeframeKey))
        ),
        item,
    ].sort((p1, p2) => {
        const firstP = order === 'asc' ? p1 : p2
        const lastP = order === 'asc' ? p2 : p1
        return (
            getValueOrPercentage(firstP, byChange, metricGroup, timeframeKey) -
            getValueOrPercentage(lastP, byChange, metricGroup, timeframeKey)
        )
    })
}

export const getSimilarPerformers = (
    item = null,
    metricGroup = null,
    performers = [],
    byChange = false,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key
) => {
    const uniqueSimilarPerformers = getUniquePerformers(performers, item, metricGroup, byChange, timeframeKey, 'desc')

    let firstIndex = uniqueSimilarPerformers.indexOf(item) - 2
    let lastIndex = uniqueSimilarPerformers.indexOf(item) + 2
    if (firstIndex < 0) {
        const diff = Math.abs(firstIndex)
        firstIndex = Math.min(firstIndex + diff, uniqueSimilarPerformers.length - 1)
        lastIndex = Math.min(lastIndex + diff, uniqueSimilarPerformers.length - 1)
    } else if (lastIndex > uniqueSimilarPerformers.length - 1) {
        const diff = lastIndex - uniqueSimilarPerformers.length + 1
        lastIndex = Math.max(lastIndex - diff, 0)
        firstIndex = Math.max(firstIndex - diff, 0)
    }

    return uniqueSimilarPerformers.slice(firstIndex, lastIndex + 1)
}

export const isGrayScaleMetric = (metricKey = '') => {
    return ['price', 'staked_tokens'].includes(metricKey)
}
