import classNames from 'classnames'
import { capitalize } from 'utils/formatter'

import styles from './tableViewSelector.module.scss'

export const TableView = {
    List: 'list',
    Grid: 'grid',
}

const ViewOption = ({ view = TableView.List, isSelected = false, onSelect = () => {} }) => (
    <div
        className={classNames(styles.viewWrap, {
            [styles.selected]: isSelected,
        })}
        onClick={() => onSelect(view)}
    >
        <p className={styles.viewText}>{`${capitalize(view)} View`}</p>
        <div className={classNames(`icon icon-${view}-view`, styles.icon)} />
    </div>
)

export const TableViewSelector = ({ selectedTableView = TableView.List, onSelect = () => {} }) => (
    <div className={styles.tableViewSelector}>
        <ViewOption
            view={TableView.List}
            isSelected={selectedTableView === TableView.List}
            onSelect={opt => {
                onSelect(opt)
            }}
        />
        <ViewOption
            view={TableView.Grid}
            isSelected={selectedTableView === TableView.Grid}
            onSelect={opt => {
                onSelect(opt)
            }}
        />
    </div>
)

export default TableViewSelector
