import { useTheme, storageState, THEME } from 'state'
import { NavBarItem } from './navbarItem'

export function ThemeNavbarItem() {
    const theme = useTheme()
    const isThemeDark = theme === THEME.DARK

    return (
        <NavBarItem
            title={isThemeDark ? 'Light' : 'Dark'}
            iconClassName={isThemeDark ? 'icon-sun' : 'icon-moon'}
            onClick={() => {
                storageState.theme.value = isThemeDark ? THEME.LIGHT : THEME.DARK
            }}
        />
    )
}
