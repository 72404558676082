import { useState } from 'react'
import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import { useTimeElapsed } from 'hooks/useTimeElapsed'
import { truncateString, MAX_TOOLTIP_TEXT_LENGTH } from 'utils/formatter'
import { TooltipOnHover } from 'components/ui/tooltip'
import { ExpandedMetric } from './expandedMetric'

import styles from './metricGroup.module.scss'
import { RaverTooltip } from 'components/assets/constants'

export const LabelWithTooltip = ({
    item = null,
    metricGroup = null,
    timeframeKey = '',
    hasExpansion = false,
    isSortableInArchive = false,
}) => {
    const updatedTimeStatus = useTimeElapsed(
        item?.metrics?.find(metric => metric?.metricKey === metricGroup?.key)?.createdAt
    )

    const label = `${metricGroup?.label ?? ''}${metricGroup?.withTimeframe ? ` ${timeframeKey}` : ''}`

    const tooltipObject = metricGroup?.withTimeframe
        ? metricGroup?.tooltipTextObj(timeframeKey)
        : metricGroup?.tooltipTextObj

    const overlay =
        (tooltipObject?.text?.length ?? 0) > MAX_TOOLTIP_TEXT_LENGTH
            ? {
                  title: tooltipObject?.title ?? '',
                  allText: tooltipObject?.text ?? '',
                  footer: tooltipObject?.footer ?? '',
              }
            : null

    const isTablet = useMediaPredicate('(min-width: 768px)')
    const [isExpanded, setIsExpanded] = useState(false)

    const isRaver = tooltipObject?.isRaver

    return (
        <TooltipOnHover
            text={isRaver ? <RaverTooltip /> : truncateString(tooltipObject?.text ?? '', MAX_TOOLTIP_TEXT_LENGTH)}
            textClassName={classNames(styles.tooltip, {
                '!w-[280px]': isRaver,
            })}
            overlay={isRaver || hasExpansion ? null : overlay}
            updatedTimeStatus={isRaver ? undefined : updatedTimeStatus}
            withExpansion={isRaver ? false : hasExpansion}
            onReadMore={() => {
                if (hasExpansion) {
                    setIsExpanded(true)
                }
            }}
            withReadMore={isRaver ? false : hasExpansion}
        >
            {hasExpansion && item && isExpanded && (
                <ExpandedMetric
                    item={item}
                    timeframeKey={timeframeKey}
                    metricGroup={metricGroup}
                    onClose={() => {
                        setIsExpanded(false)
                    }}
                    isSortableInArchive={isSortableInArchive}
                    isTablet={isTablet}
                />
            )}
            <div className={styles.labelWrap}>
                <p
                    className={classNames(styles.label, 'flex items-center gap-1', {
                        [styles.long]: String(label).length > 20,
                    })}
                >
                    {isRaver ? (
                        <>
                            Effectiveness
                            <span className='icon icon-candy !bg-contrast-2' />
                        </>
                    ) : (
                        label
                    )}
                </p>
            </div>
        </TooltipOnHover>
    )
}
