import { useRouter } from 'next/router'
import { HeaderLogo } from './headerLogo'
import { useBreadcrumbs } from './useBreadcrumbs'
import { Link } from 'components/ui'

export function Breadcrumbs() {
    const router = useRouter()

    const breadcrumbs = useBreadcrumbs(router.asPath)

    return (
        <div className='flex items-center gap-1 py-5'>
            <HeaderLogo />
            {breadcrumbs.map((breadcrumb, index) => (
                <div key={breadcrumb.link} className='items-center gap-1 hidden md:flex'>
                    <span className='icon icon-angle-right' />
                    {index === breadcrumbs.length - 1 ? (
                        <span className='text-contrast-3'>{breadcrumb.title}</span>
                    ) : (
                        <Link
                            href={breadcrumb.link}
                            className={'text-contrast-3 hover:text-contrast-4 hover:underline'}
                        >
                            {breadcrumb.title}
                        </Link>
                    )}
                </div>
            ))}
        </div>
    )
}
