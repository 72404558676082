import { useRef, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'

export const usePersistPortal = (className = '') => {
    const container = document.getElementById('portal-root')

    const portalRef = useRef()
    const getPortal = useCallback(() => {
        if (!portalRef.current) {
            portalRef.current = document.createElement('div')
            if (className) {
                className.split(' ').forEach(name => {
                    portalRef.current.classList.add(name)
                })
            }
        }
        return portalRef.current
    }, [className])

    useEffect(() => {
        const root = container instanceof Element ? container : (container?.current ?? document.body)

        const portal = getPortal()
        root.appendChild(portal)
        return () => {
            root.removeChild(portal)
        }
    }, [container, getPortal])

    return useCallback(({ children }) => createPortal(children, getPortal()), [getPortal])
}

export const Portal = ({ className = '', children }) => {
    const PortalComponent = usePersistPortal(className)
    return <PortalComponent>{children}</PortalComponent>
}
