import { useState, useRef, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import numeral from 'numeral'
import { useClickOutside } from 'hooks'
import {
    DECIMALS_LIMIT_DEFAULT,
    isInvalidNumber,
    formatInputNumber,
    formatOutputNumber,
    countDecimalPlaces,
} from 'utils/formatter'
import { isIncompleteValidTyping, validateWithinMinMaxRange, replaceTypedCommaWithDot } from './utils'

import styles from './editableNumber.module.scss'

export const EditableNumber = ({
    value = '',
    onValueChange = () => {},
    className = '',
    minValue = undefined,
    maxValue = undefined,
    defaultValue = '',
    decimalsLimit = DECIMALS_LIMIT_DEFAULT,
    width = 0,
}) => {
    const inputRef = useRef(null)

    const [isEditing, setIsEditing] = useState(false)
    const [inputValue, setInputValue] = useState(
        formatOutputNumber(value, {
            precision: Math.min(countDecimalPlaces(value), decimalsLimit),
            allowEmpty: true,
            withAbbreviation: false,
            forcePrecision: true,
            showApproximation: false,
        })
    )

    useEffect(() => {
        setInputValue(
            formatOutputNumber(value, {
                precision: Math.min(countDecimalPlaces(value), decimalsLimit),
                allowEmpty: true,
                withAbbreviation: false,
                forcePrecision: true,
                showApproximation: false,
            })
        )
    }, [decimalsLimit, value])

    const onSetValueOnSubmit = useCallback(
        (submittedValue = '') => {
            // Parse newValue with numeral to get the unformatted value
            const newValue = numeral(submittedValue).value() ?? ''

            if (newValue === '') {
                if (defaultValue === '') {
                    setInputValue('')
                    onValueChange('')
                } else {
                    const precision = Math.min(countDecimalPlaces(defaultValue), decimalsLimit)
                    // Reset to the default value
                    setInputValue(
                        formatOutputNumber(defaultValue, {
                            precision,
                            allowEmpty: true,
                            withAbbreviation: false,
                            forcePrecision: true,
                            showApproximation: false,
                        })
                    )
                    onValueChange(formatInputNumber(Number(defaultValue), precision))
                }
            } else {
                validateWithinMinMaxRange(
                    newValue,
                    minValue,
                    maxValue,
                    decimalsLimit,
                    setInputValue,
                    onValueChange,
                    true
                )
            }
        },
        [decimalsLimit, defaultValue, maxValue, minValue, onValueChange]
    )

    const onSetValueOnChange = useCallback(
        (_rawInputValue = '') => {
            const rawInputValue = replaceTypedCommaWithDot(inputValue, _rawInputValue)
            if (isIncompleteValidTyping(rawInputValue, decimalsLimit > 0, minValue < 0, decimalsLimit)) {
                // Allow typing values like "123."
                setInputValue(rawInputValue)
            } else {
                // Parse newValue with numeral to get the unformatted value
                const newValue = numeral(rawInputValue).value() ?? ''

                const precision = Math.min(countDecimalPlaces(newValue), decimalsLimit)

                if (newValue === '') {
                    setInputValue('')
                    if (defaultValue === '') {
                        onValueChange('')
                    }
                } else {
                    const croppedValue = formatInputNumber(Number(newValue), precision)
                    if (isInvalidNumber(croppedValue)) {
                        // ignore
                    } else {
                        validateWithinMinMaxRange(
                            croppedValue,
                            minValue,
                            maxValue,
                            precision,
                            setInputValue,
                            onValueChange,
                            false
                        )
                    }
                }
            }
        },
        [decimalsLimit, defaultValue, inputValue, maxValue, minValue, onValueChange]
    )

    // Save and validate the value when clicking outside the input
    useClickOutside(inputRef, () => {
        if (isEditing) {
            setIsEditing(false)

            onSetValueOnSubmit(inputValue)
        }
    })

    return (
        <div
            className={classNames(styles.editableNumber, className)}
            onClick={() => {
                if (!isEditing) {
                    setIsEditing(true)
                }
            }}
        >
            <input
                tabIndex={1}
                className={styles.input}
                ref={inputRef}
                type='text'
                inputMode={decimalsLimit > 0 ? 'decimal' : 'numeric'}
                pattern='[0-9]*'
                step='any'
                title=''
                value={inputValue}
                min={minValue}
                max={maxValue}
                style={{
                    width: `calc(${width}px + 32px)`,
                }}
                onChange={e => {
                    onSetValueOnChange(e.target.value)
                }}
                onFocus={e => {
                    if (!isEditing) {
                        e.target.select()
                    }
                }}
                onBlur={e => {
                    // Hovering the chart steals the focus from the input.
                    // Force keeping the focus on the input if still typing.
                    if (isEditing) {
                        e.target.focus()
                    } else {
                        onSetValueOnSubmit(inputValue)
                    }
                }}
                onKeyDown={e => {
                    if (e.key === 'e' || (minValue >= 0 && e.key === '-')) {
                        e.preventDefault()
                    }
                }}
            />
        </div>
    )
}
