import { getAssetMetricGroupsPerCategory } from 'components/assets/archive/utils'
import { DEFAULT_ASSETS_SORT_BY } from 'components/assets/constants'
import { getProviderMetricGroupsPerCategory } from 'components/providers/archive/utils'
import {
    getRewardOptionMetricGroupsPerCategory,
    getSupportedRewardOptionCategoryKeys,
} from 'components/rewardOptions/archive/utils'
import { DEFAULT_REWARD_OPTIONS_SORT_BY, STAKED_TOKENS_METRIC_KEY } from 'components/rewardOptions/constants'
import { gql } from 'graphql-request'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import {
    ALL_KEY,
    ASSET_CATEGORIES,
    ASSETS_UNDER_MANAGEMENT_METRIC_KEY,
    AVS_KEY,
    CUSTOM_AUM_CHANGE_METRIC_KEY,
    CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
    DEFAULT_ASSET_TIMEFRAME,
    DEFAULT_ORDER,
    DEFAULT_PROVIDER_TIMEFRAME,
    DEFAULT_REWARD_OPTION_TIMEFRAME,
    HOSTING_KEY,
    LIQUID_STAKING_PROVIDER_KEY,
    OPERATOR_KEY,
    PAGE_SIZE_10,
    PAGE_SIZE_20,
    POS_PROVIDER_KEY,
    RewardOptionType,
    SOLO_STAKING_KEY,
    STABLECOINS_ASSET_CATEGORY,
    TYPE_ASSET,
    TYPE_PROVIDER,
} from 'utils/constants'
import { capitalize, toCamelCase } from 'utils/formatter'
import {
    getMetricsQuery,
    providerQuery,
    providerQueryWithMetrics,
    relatedAssetOfRewardOptionQuery,
    swrParameters,
} from './queries'

export const useSWRInfiniteItems = (query = '', variables = {}, checkNext = false) => {
    return useSWRInfinite(
        index => {
            const limit = variables?.limit ?? 0

            const allVariables = {
                ...variables,
                // if checkNext is true, a batch of size = pageSize + 1 is expected to be fetched
                // to check the existence of at least one item in the next batch
                offset: index * (checkNext ? limit - 1 : limit),
            }

            return [query, allVariables]
        },
        null,
        { ...swrParameters, revalidateFirstPage: false }
    )
}

// ---------- ASSET ARCHIVE PAGINATION DATA HOOKS ---------- //

function buildPaginatedAssetsForArchiveQuery(
    wherePayload = {
        search: '',
        category: null,
        sortBy: DEFAULT_ASSETS_SORT_BY.key,
        order: DEFAULT_ORDER.key,
        timeframe: DEFAULT_ASSET_TIMEFRAME.key,
        byChange: false,
        weekAgoDate: '', // format: 2023-01-01
    }
) {
    const tagsString = Boolean(wherePayload?.category)
        ? `tags: { ${
              wherePayload?.category === ALL_KEY
                  ? `tagKeys: ${JSON.stringify(ASSET_CATEGORIES.map(c => c?.key).filter(key => key !== ALL_KEY))}`
                  : `tagKeys: ["${wherePayload?.category}"]`
          } }`
        : ''

    let providersString = ''
    if (wherePayload?.category === STABLECOINS_ASSET_CATEGORY.key) {
        providersString = `rewardOptionsWithAssetAsInput(limit: 8) { id stakeableInApp ${providerQuery} }`
    } else if (wherePayload?.category === LIQUID_STAKING_PROVIDER_KEY) {
        providersString = gql`
            rewardOptionsWithAssetAsOutput(limit: 1) { 
                id 
                stakeableInApp 
                ${providerQuery} 
                inputAssets(limit: 1) { slug } 
            }
        `
    }

    const isPercentage =
        getAssetMetricGroupsPerCategory(wherePayload?.category)?.find(group => group.key === wherePayload.sortBy)
            ?.postfix === '%'
    const changeAbsolutesOrPercentages = isPercentage ? 'changeAbsolutes' : 'changePercentages'

    const changesSortBy = wherePayload.byChange ? `${changeAbsolutesOrPercentages}Key: _${wherePayload.timeframe}` : ''

    return gql`
        query getPaginatedAssetsArchive(
            $limit: Int!
            $offset: Int!
        ) {
            assets: assets(
                where: {
                    isLaunched: true
                    underMaintenance: false
                    excludeFromRanking: false
                    search: "${wherePayload.search}"
                    ${tagsString}
                }
                order: { 
                    metricKey_${wherePayload.order}: "${wherePayload.sortBy}" ${changesSortBy}
                }
                limit: $limit
                offset: $offset
            ) {
                id
                slug
                name
                tags {
                    tagKey
                }
                logoUrl
                symbol
                links
                metaWebApp
                ${getMetricsQuery([
                    'real_reward_rate',
                    'total_roi_365d',
                    'staking_roi_365d',
                    'price_roi_365d',
                    'staked_tokens_trend_24h',
                    'trading_volume_trend_24h',
                    'marketcap',
                    'daily_trading_volume',
                    'inflation_rate',
                    'total_staking_wallets',
                    'staking_ratio',
                    'staking_marketcap',
                    'restaking_marketcap',
                    'staked_tokens',
                    'restaked_tokens',
                    'price',
                    'annualized_rewards_usd',
                    'reward_rate',
                    'net_staking_flow',
                    'peg_deviation',
                    'exchange_ratio',
                    'market_ratio',
                    'operators',
                    'restaking_wallets',
                ])}
                ${providersString}
            }
            assetsWithMetrics: assets(
                where: {
                    isLaunched: true
                    underMaintenance: false
                    excludeFromRanking: false
                    search: "${wherePayload.search}"
                    ${tagsString}
                }
                order: { 
                    metricKey_${wherePayload.order}: "${wherePayload.sortBy}" 
                }
                limit: $limit
                offset: $offset
            ) {
                id
                slug
                metrics(showAll: true, limit: 8, where: { metricKeys: ["net_staking_flow"], createdAt_gt: "${
                    wherePayload.weekAgoDate
                }" }, interval: day) {
                    metricKey
                    createdAt
                    defaultValue
                    variation
                }
            }
        }
    `
}

// Appended pages of assets
export function usePaginatedAssetsForArchiveAppended(
    wherePayload = {
        search: '',
        category: null,
        sortBy: DEFAULT_ASSETS_SORT_BY.key,
        order: DEFAULT_ORDER.key,
        timeframe: DEFAULT_ASSET_TIMEFRAME.key,
        byChange: false,
        weekAgoDate: '', // format: 2023-01-01
    },
    limit = PAGE_SIZE_20,
    checkNext = false
) {
    const query = buildPaginatedAssetsForArchiveQuery(wherePayload)
    return useSWRInfiniteItems(
        query,
        {
            limit,
        },
        checkNext
    )
}

// ---------- ASSET/PROVIDER PROFILE PAGINATION DATA HOOKS ---------- //

export function getFilterPaginatedRewardOptionsForProfileQuery(
    profileType = TYPE_ASSET,
    category = null,
    slug = '',
    search = ''
) {
    const isAVS = category === AVS_KEY

    const typeString = Boolean(category)
        ? category === ALL_KEY
            ? `typeKeys: ${JSON.stringify(getSupportedRewardOptionCategoryKeys(profileType, slug))}`
            : `typeKeys: ["${
                  category === 'pos'
                      ? 'dual-staking", "pos'
                      : category === 'run-validator'
                        ? 'hosting'
                        : category === 'smart-contract'
                          ? 'smart-contract", "lending'
                          : category
              }"${
                  category === LIQUID_STAKING_PROVIDER_KEY && profileType === TYPE_PROVIDER ? `, "${OPERATOR_KEY}"` : ''
              }]`
        : ''

    const inputAssetString = !isAVS
        ? `inputAsset: { ${profileType === TYPE_ASSET ? `slugs: ["${slug}"]` : `search: "${search}", isActive: True`}}`
        : ''

    const validatorsFilter = [
        'run-validator',
        'solo-staking',
        'hosting',
        'liquid-staking',
        'partial-staking',
        'smart-contract',
        'lending',
        'custodial',
        'operator',
        'all',
    ].includes(category)
        ? ''
        : `validators: { status: { labels: ["active", "standby"] } }`

    return gql`{
        ${isAVS ? '' : 'isActive: True'}
        ${typeString}
        ${profileType !== TYPE_PROVIDER ? '' : `providers: { slugs: ["${slug}"]}`}
        ${inputAssetString}
        ${isAVS ? `chainKeys:["${slug}"]` : ''}
        ${validatorsFilter}
    }`
}

function buildPaginatedRewardOptionsForProfileQuery(
    wherePayload = {
        search: '',
        category: null,
        sortBy: DEFAULT_REWARD_OPTIONS_SORT_BY.key,
        order: DEFAULT_ORDER.key,
        timeframe: DEFAULT_REWARD_OPTION_TIMEFRAME.key,
        byChange: false,
        profileSlug: null,
        showVerifiedFirst: false,
    },
    // on the asset profile, show providers, and vice versa
    profileType = TYPE_ASSET
) {
    const verifiedString =
        profileType === TYPE_ASSET && wherePayload?.showVerifiedFirst ? 'providerIsVerified: desc' : ''

    const sortByString =
        wherePayload.sortBy === CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY ? STAKED_TOKENS_METRIC_KEY : wherePayload.sortBy

    const isPercentage =
        getRewardOptionMetricGroupsPerCategory(wherePayload?.category, false, true)?.find(
            group => group.key === wherePayload.sortBy
        )?.postfix === '%'
    const changeAbsolutesOrPercentages = isPercentage ? 'changeAbsolutes' : 'changePercentages'

    const changesSortBy =
        wherePayload.sortBy === CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY
            ? 'changeAbsolutesKey: _7d'
            : wherePayload.byChange
              ? `${changeAbsolutesOrPercentages}Key: _${wherePayload.timeframe}`
              : ''

    const isPoS = wherePayload?.category === RewardOptionType.PROOF_OF_STAKE

    const providerPart = `
              providers (limit:2) {
                name,
                logoUrl,
                slug,
                isVerified,
                type{label, key}
                metrics(where: {metricKeys:["vsp"]},limit:100) {
                  metricKey
                  defaultValue
                  variation
                }
            }
              `
    return gql`
        query getPaginatedRewardOptionsForProfile(
            $limit: Int!
            $offset: Int!
        ) {
            rewardOptions(
                limit: $limit
                offset: $offset
                where: ${getFilterPaginatedRewardOptionsForProfileQuery(
                    profileType,
                    wherePayload?.category,
                    wherePayload?.profileSlug,
                    wherePayload?.search
                )}
                order: { 
                    ${isPoS ? 'validatorStatus: desc,' : ''}
                    ${verifiedString}
                    metricKey_${wherePayload.order}: "${sortByString}" ${changesSortBy}
                }
            ) {
                id
                label
                links
                stakeableInApp
                chains { key }
                type {
                    key
                }
                isActive
                chains {
                    key
                    label
                }
                ${profileType === TYPE_ASSET ? providerQueryWithMetrics : ''}
                ${relatedAssetOfRewardOptionQuery}
                ${profileType === TYPE_PROVIDER ? providerPart : ''}
                outputAssets(where: { isActive: True }, limit: 1) {
                    id
                    name
                    slug
                    symbol
                    logoUrl
                    description
                    isActive
                    isLaunched
                    metaWebApp
                    links
                    tags {tagKey, label}
                    metrics(limit:100){
                        defaultValue
                        metricKey
                        variation
                    }
                }
                ${getMetricsQuery([
                    'lockup_time',
                    'price',
                    'min_deposit',
                    'max_deposit',
                    'stakelink',
                    'reward_rate',
                    'reward_frequency',
                    'minimum',
                    'commission',
                    'hosting_fee',
                    'staked_tokens',
                    'staking_share',
                    'self_staked_tokens',
                    'staking_wallets',
                    'performance',
                    'annualized_rewards_usd',
                    'assets_under_management',
                    'is_not_delegation_node',
                    'supported_chains',
                    'total_supported_chains',
                    'points_per_block',
                    'risk_rating',
                    'net_staking_flow',
                    'net_staking_flow_7d',
                ])}
                validators(
                    limit: 500, 
                    where: { 
                        isActive: True, 
                        status: { labels: ["active", "standby"] } 
                    }, 
                    order: { status: desc }
                ) {
                    id
                    address
                    isActive
                    chain {
                        key
                    }
                    isPrivate
                    status {
                        label
                    }
                    links
                    ${getMetricsQuery([
                        'min_nominator_stake',
                        'reward_rate',
                        'reward_frequency',
                        'minimum',
                        'commission',
                        'hosting_fee',
                        'staked_tokens',
                        'staking_share',
                        'self_staked_tokens',
                        'staking_wallets',
                        'performance',
                        'node_id',
                        'is_delegation_node',
                    ])}
                }
            }
        }
    `
}

// Appended pages of ROs
export function usePaginatedRewardOptionsForProfileAppended(
    wherePayload = {
        search: '',
        category: null,
        sortBy: DEFAULT_REWARD_OPTIONS_SORT_BY.key,
        order: DEFAULT_ORDER.key,
        timeframe: DEFAULT_REWARD_OPTION_TIMEFRAME.key,
        byChange: false,
        profileSlug: null,
        showVerifiedFirst: false,
    },
    limit = PAGE_SIZE_20,
    checkNext = false,
    profileType = TYPE_ASSET
) {
    const query = buildPaginatedRewardOptionsForProfileQuery(wherePayload, profileType)
    return useSWRInfiniteItems(
        query,
        {
            limit,
            profileSlug: wherePayload.profileSlug,
            search: wherePayload.search,
        },
        checkNext
    )
}

export function useCountRewardOptions(categoryKeys = [], slug = '', profileType = TYPE_ASSET, search = '') {
    let queries = ''
    categoryKeys.forEach(categoryKey => {
        let query = ''
        if (categoryKey === RewardOptionType.CUSTODIAL) {
            query = gql`
                meta {
                    providers(where: ${getFilterPaginatedCustodialRewardOptionsForProfileQuery(slug)}) { count }
                }
            `
        } else if (categoryKey === RewardOptionType.SMART_CONTRACT) {
            query = gql`
                meta {
                    rewardOptions(where: ${getFilterPaginatedSmartContractRewardOptionsForProfileQuery(slug)}) { count }
                }
            `
        } else {
            query = gql`
                meta {
                    rewardOptions(where: ${getFilterPaginatedRewardOptionsForProfileQuery(
                        profileType,
                        categoryKey,
                        slug,
                        search
                    )}) { count }
                }
            `
        }
        queries += `${toCamelCase(categoryKey)}: ${query}, `
    })

    queries = gql`{ ${queries} }`

    return useSWR([queries, {}])
}

export function getFilterPaginatedCustodialRewardOptionsForProfileQuery(slug) {
    return gql`{ typeKeys: ["custodial"], rewardOptions: { isActive: True, inputAsset: { slugs: ["${slug}"] }, typeKeys: ["custodial"] } }`
}

export function usePaginatedCustodialRewardOptionsForProfile(slug = '', limit = PAGE_SIZE_20, checkNext = false) {
    const query = gql`
        query fetchCustodialRewardOptions($limit: Int!, $offset: Int!) {
            providers(where: ${getFilterPaginatedCustodialRewardOptionsForProfileQuery(
                slug
            )}, limit: $limit, offset: $offset) {
                slug
                name
                type {
                    key
                }
                logoUrl
                country
                isClaimed
                isVerified
                links
                rewardOptions(
                    limit: 500
                    where: {
                        isActive: True
                        inputAsset: { slugs: ["${slug}"] }
                        typeKeys: ["custodial"]
                    }
                    order: { 
                        metricKey_desc: "reward_rate"
                    }
                ) {
                   id
                    label
                    ${getMetricsQuery([
                        'reward_rate',
                        'commission',
                        'lockup_time',
                        'min_deposit',
                        'max_deposit',
                        'stakelink',
                    ])}
                }
            }
        }
    `
    return useSWRInfiniteItems(
        query,
        {
            limit,
        },
        checkNext
    )
}

function getFilterPaginatedSmartContractRewardOptionsForProfileQuery(slug) {
    return gql`{ inputAsset: { slugs: ["${slug}"] }, typeKeys: ["smart-contract", "lending", "borrowing", "liquidity-pool", "restaking"] }`
}

const getSmartContractRewardOptionQuery = wherePayload => {
    const orderBy = wherePayload?.sortBy
    return gql`
        query getPaginatedSmartContractRewardOptionsForProfile(
            $limit: Int!
            $offset: Int!
        ) {
            rewardOptions(
                where: ${getFilterPaginatedSmartContractRewardOptionsForProfileQuery(wherePayload?.profileSlug)}
                limit: $limit
                offset: $offset
                order: { 
                    metricKey_${wherePayload.order}: "${wherePayload.sortBy}"
                }
            ) {
                type {
                    key
                }
                id
                label
                rewardInfo
                inputAssetsBreakdown
                rewardFormula
                stakeableInApp
                links
                chains {
                    key
                    label
                }
                providers(where: { isActive: True }, limit: 1) {
                    id
                    name
                    isClaimed
                    slug
                    logoUrl
                    isVerified
                    isActive
                    metrics(where: {metricKeys:["vsp"]},limit:100) {
                        metricKey
                        defaultValue
                        variation
                    }
                }
                ${getMetricsQuery([
                    'staking_share',
                    'risk_rating',
                    'total_value_locked',
                    'reward_rate',
                    'reward_frequency',
                    'lockup_time',
                    'unstaking_time',
                    'staked_tokens',
                    'staking_wallets',
                ])}
                inputAssets(where: { isActive: True }, limit: 500) {
                    id
                    name
                    slug
                    symbol
                    logoUrl
                    description
                    isActive
                    metrics(limit:1, where: {metricKeys:["price"]}) {   
                        defaultValue
                        label
                        metricKey
                    }
                }
                outputAssets(where: { isActive: True }, limit: 1) {
                    id
                    name
                    slug
                    symbol
                    logoUrl
                    description
                    isActive
                    isLaunched
                    metaWebApp
                }
            }
        }
    `
}

export function usePaginatedAdditionalRewardOptionsForDropdownAppended(assetSlug = '', pageSize = PAGE_SIZE_20) {
    const query = gql`
        query getPaginatedAdditionalRewardOptions(
            $limit: Int!
            $offset: Int!
        ) {
            rewardOptions(
                limit: $limit,
                offset: $offset,
                where: {
                    isActive: True,
                    inputAsset: { slugs: ["${assetSlug}"] },
                }
            ) {
                id
                type {
                    key
                }
                label
                providers(where: { isActive: True }, limit: 1) {
                    name
                    slug
                    logoUrl
                    isVerified
                }
                ${getMetricsQuery(['reward_rate'])}
            }
        }
    `

    return useSWRInfiniteItems(
        query,
        {
            limit: pageSize,
        },
        false
    )
}

// Appended infinitely loaded validators
export function usePaginatedValidatorsForProfile(
    wherePayload = {
        sortBy: DEFAULT_REWARD_OPTIONS_SORT_BY.key,
        order: DEFAULT_ORDER.key,
        rewardOptionId: null,
    },
    limit = PAGE_SIZE_10,
    checkNext = false
) {
    const query = gql`
        query getPaginatedValidatorsForProfile(
            $rewardOptionId: String!
            $limit: Int!
            $offset: Int!
        ) {
            validators(
                limit: $limit, 
                offset: $offset, 
                where: { isActive: True, status: { labels: ["active", "standby"] }, rewardOptions: { ids: [$rewardOptionId] } }, 
                order: { 
                    status: desc,
                    metricKey_${wherePayload.order}: "${wherePayload.sortBy}" 
                }
            ) {
                id
                address
                isActive
                status {
                    label
                }
                ${getMetricsQuery([
                    'reward_rate',
                    'reward_frequency',
                    'minimum',
                    'commission',
                    'hosting_fee',
                    'staked_tokens',
                    'staking_share',
                    'self_staked_tokens',
                    'assets_under_management',
                    'staking_wallets',
                    'performance',
                ])}
            }
        }
    `

    return useSWRInfiniteItems(
        query,
        {
            limit,
            rewardOptionId: wherePayload.rewardOptionId,
        },
        checkNext
    )
}

export function usePaginatedOtherRewardOptionAssetsForProfile(slug = '', limit = 10, checkNext = false) {
    const query = gql`
        query getPaginatedOtherROAssetsForProfile($limit: Int!, $offset: Int!) {
            rewardOptions(
                limit: $limit
                offset: $offset
                where: {
                    providers: { slugs: ["${slug}"] }
                    inputAsset: { isActive: False }
                }
                order: { validatorStatus: desc, metricKey_desc: "staking_wallets" }
            ) {
                id
                inputAssets(limit: 1, where: { isActive: False }) {
                    id
                    name
                    slug
                    symbol
                    logoUrl
                    ${getMetricsQuery(['price'])}
                }
                ${getMetricsQuery(['staking_wallets', 'staked_tokens'])}
                validators(
                    limit: 1
                    offset: 0
                    where: {
                        isActive: True
                        status: { labels: ["active", "standby"] }
                    },
                    order: { status: desc, metricKey_desc: "staking_wallets" }
                ) {
                    id
                    address
                    isActive
                    status {
                        label
                    }
                }
            }
        }
    `

    return useSWRInfiniteItems(
        query,
        {
            limit,
        },
        checkNext
    )
}

// ---------- PROVIDER ARCHIVE PAGINATION DATA HOOKS ---------- //

function buildPaginatedProvidersForArchiveQuery(
    wherePayload = {
        search: '',
        asset: null,
        category: null,
        sortBy: null,
        order: DEFAULT_ORDER.key,
        timeframe: DEFAULT_PROVIDER_TIMEFRAME.key,
        byChange: false,
        showVerifiedFirst: true,
    }
) {
    const categories = wherePayload?.category
        ? wherePayload.category === ALL_KEY
            ? [POS_PROVIDER_KEY, SOLO_STAKING_KEY, LIQUID_STAKING_PROVIDER_KEY, HOSTING_KEY, OPERATOR_KEY]
            : [wherePayload.category]
        : []

    const assetForROs = Boolean(wherePayload?.asset) ? `inputAsset: { slugs: ["${wherePayload?.asset}"]}` : ''

    const typesForROs = Boolean(categories?.length)
        ? `typeKeys: [${categories.map(item => `"${item}"`).join(', ')}],`
        : ''
    const typeString = typesForROs ? `rewardOptions: { ${typesForROs} ${assetForROs} }` : ''

    // Rewards options are fetched to show "Supported assets".
    // Requirement: Do not show "Supported assets" of operator ROs
    // (but still show the provider)
    const typesForROsNoOperators = Boolean(categories?.length)
        ? `typeKeys: [${categories
              .filter(c => c !== OPERATOR_KEY)
              .map(item => `"${item}"`)
              .join(', ')}],`
        : ''

    const verifiedOrderString = wherePayload?.showVerifiedFirst ? 'isVerified: desc' : ''

    let metricOrderString = ''
    if (Boolean(wherePayload?.sortBy)) {
        let sortBy = wherePayload.sortBy
        let byChange = wherePayload.byChange
        let changeAbsolutesOrPercentages = ''
        if (wherePayload?.sortBy === CUSTOM_AUM_CHANGE_METRIC_KEY) {
            changeAbsolutesOrPercentages = 'changeAbsolutes'
            byChange = true
            sortBy = ASSETS_UNDER_MANAGEMENT_METRIC_KEY
        } else {
            const isPercentage =
                getProviderMetricGroupsPerCategory(wherePayload?.category)?.find(
                    group => group.key === wherePayload.sortBy
                )?.postfix === '%'
            changeAbsolutesOrPercentages = isPercentage ? 'changeAbsolutes' : 'changePercentages'
        }
        const changesSortBy = byChange ? `${changeAbsolutesOrPercentages}Key: _${wherePayload.timeframe}` : ''
        metricOrderString = `metricKey_${wherePayload.order}: "${sortBy}" ${changesSortBy}`
    }

    const orderString =
        verifiedOrderString || metricOrderString
            ? `order: { ${[verifiedOrderString, metricOrderString].filter(Boolean).join(', ')} },`
            : ''

    return gql`
        query getPaginatedProviderArchive(
            $limit: Int!
            $offset: Int!
        ) {
            providers(
                where: {
                    isActive: True
                    isClaimed: true
                    search: "${wherePayload.search}"
                    ${typeString}
                },
                ${orderString}
                limit: $limit, 
                offset: $offset
            ) {
                slug
                name
                logoUrl
                country
                isReferral
                isVerified
                links
                rewardOptions(
                    limit: 6, 
                    where: { 
                        ${typesForROsNoOperators ?? ''}
                        inputAsset: { isActive: True }
                    }, 
                    order: { metricKey_desc: "staked_tokens" }
                ) {
                    inputAssets(limit: 1) {
                        logoUrl
                        slug
                    }
                }
                ${getMetricsQuery(['assets_under_management', 'staking_wallets', 'hosting_fee', 'vsp'])}
            }
        }
    `
}

// Appended pages of assets
export function usePaginatedProvidersForArchiveAppended(
    wherePayload = {
        search: '',
        asset: null,
        category: null,
        sortBy: null,
        order: DEFAULT_ORDER.key,
        timeframe: DEFAULT_PROVIDER_TIMEFRAME.key,
        byChange: false,
        showVerifiedFirst: true,
    },
    limit = PAGE_SIZE_20,
    checkNext = false
) {
    const query = buildPaginatedProvidersForArchiveQuery(wherePayload)
    return useSWRInfiniteItems(
        query,
        {
            limit,
        },
        checkNext
    )
}

export const useOperatorsForRewardoptions = slug => {
    const query = gql`
        query getOperatorsForRewardOptions {
            rewardOptions(where: {inputAsset: {slugs: ["${slug}"]}, typeKeys: ["operator"],isActive: True}, limit: 500) {
                type {label}
                links
                metrics(where: {metricKeys:["staked_tokens", "staking_share", "performance", "commission", "staking_wallets", "net_staking_flow"]}, limit: 100) {
                    metricKey
                    defaultValue
                    variation
                    changePercentages
                }
                providers(limit: 2, where: {isClaimed: true}) {
                    id
                    name
                    slug
                    type{label}
                    logoUrl
                    isVerified
                    metrics(where: {metricKeys:["vsp"]},limit:100) {
                        metricKey
                        defaultValue
                        variation
                    }
                }
            }
        }
    `
    return useSWRInfiniteItems(query, {}, false)
}

export function usePaginatedRewardOptionsForProfile(
    category,
    wherePayload = {
        search: '',
        category: null,
        sortBy: DEFAULT_REWARD_OPTIONS_SORT_BY.key,
        order: DEFAULT_ORDER.key,
        timeframe: DEFAULT_REWARD_OPTION_TIMEFRAME.key,
        byChange: false,
        profileSlug: null,
        showVerifiedFirst: false,
        profileType: TYPE_ASSET,
        rewardOptionId: null,
    },
    limit = PAGE_SIZE_20,
    checkNext = false
) {
    const isSmartContract =
        category === RewardOptionType.SMART_CONTRACT ||
        category === RewardOptionType.LENDING ||
        category === RewardOptionType.RESTAKING ||
        category === RewardOptionType.BORROWING ||
        category === RewardOptionType.LIQUIDITY_POOL
    // Smart contract (DeFi) uses different query
    const query = isSmartContract
        ? getSmartContractRewardOptionQuery(wherePayload)
        : buildPaginatedRewardOptionsForProfileQuery(wherePayload, TYPE_ASSET)

    return useSWRInfiniteItems(
        query,
        {
            limit,
            profileSlug: wherePayload.profileSlug,
            search: wherePayload.search,
        },
        checkNext
    )
}

export function usePaginatedItemsForStaking(
    type = TYPE_ASSET,
    wherePayload = {},
    // NB: order's object parsing is broken in the backend, passing temporarily as string
    orderPayloadString = '',
    limit = PAGE_SIZE_20
) {
    const typePayload = type === TYPE_ASSET ? 'assets' : 'rewardOptions'

    const assetContent = gql`
        id
        slug
        name
        symbol
        logoUrl
        ${getMetricsQuery(['price'])}
    `

    const withValidators = type === TYPE_PROVIDER
    const validatorQuery = withValidators
        ? gql`
            validators(
                limit: 1, 
                ${
                    wherePayload?.validators?.addresses
                        ? `where: { 
                    addresses: ${JSON.stringify(wherePayload?.validators?.addresses)},
                }`
                        : ''
                }, 
            ) {
                id
                address
                status {
                    label
                }
            }`
        : ''

    const rewardOptionContent = gql`
        id
        type {
            key
        }
        label
        rewardFormula
        stakeableInApp
        ${providerQuery}
        ${getMetricsQuery(['reward_rate', 'supported_chains'])}
        inputAssets(limit: 1) {
            ${assetContent}
        }
        outputAssets (limit: 1) {
            ${assetContent}
        }
        ${validatorQuery}
    `

    const query = gql`
        query getPaginated${capitalize(type)}Items(
            $limit: Int!
            $offset: Int!
            $where: ${capitalize(typePayload)}Where!
        ) {
            ${typePayload}(
                limit: $limit
                offset: $offset
                where: $where
                order: ${orderPayloadString}
            ) {
                ${type === TYPE_ASSET ? assetContent : rewardOptionContent}
            }
        }
    `
    let variables = { where: wherePayload, limit }

    return useSWRInfinite(
        index => {
            variables = { ...variables, offset: index * limit }
            return [query, variables]
        },
        null,
        { ...swrParameters, revalidateFirstPage: false }
    )
}
