import { useNavigationContext } from 'contexts/navigationContext'

const breadcrumbsConfig = [
    {
        part: 'assets',
        title: 'Assets',
    },
    {
        part: 'asset',
        title: 'Assets',
        link: 'assets',
        children: [{ part: '*' }],
    },
    {
        part: 'providers',
        title: 'Providers',
    },
    {
        part: 'provider',
        title: 'Providers',
        link: 'providers',
        children: [{ part: '*' }],
    },
    {
        part: 'swap',
        title: 'Swap',
    },
    {
        part: 'stake-app',
        title: 'Stake',
    },
    {
        part: 'terminal',
        title: 'Optimize',
        children: [{ part: 'dashboard', title: 'My Holdings' }],
    },
    {
        part: 'staking-data-api',
        title: 'API',
    },
    {
        part: 'summit',
        title: 'Summit',
    },
    {
        part: 'sreth',
        title: 'srETH',
    },
    {
        part: 'journal',
        title: 'Journal',
        children: [
            {
                part: 'research',
                title: 'Research',
                children: [{ part: '*' }],
            },
            {
                part: 'guides',
                title: 'Research',
                children: [{ part: '*' }],
            },
            {
                part: 'infographics',
                title: 'Infographics',
                children: [{ part: '*' }],
            },
            {
                part: 'news',
                title: 'News',
                children: [{ part: '*' }],
            },
            {
                part: 'interviews',
                title: 'Interviews',
                children: [{ part: '*' }],
            },
            {
                part: 'staking-insider',
                title: 'Staking Insider',
                children: [{ part: '*' }],
            },
        ],
    },
    {
        part: 'calculator',
        title: 'Calculator',
    },
    {
        part: 'vsp',
        title: 'VSP',
    },
    {
        part: 'disclaimer',
        title: 'Disclaimer',
    },
    {
        part: 'privacy-policy',
        title: 'Privacy Policy',
    },
    {
        part: 'terms-of-service',
        title: 'Terms of Service',
    },
]

function getBreadcrumbs(path, breadcrumbTitle) {
    const parts = path.split('?')[0].split('/').filter(Boolean)
    const breadcrumbs = []
    let currentConfig = breadcrumbsConfig

    for (let i = 0; i < parts.length; i++) {
        const part = parts[i]
        const configMatch = currentConfig.find(item => item.part === part || item.part === '*')

        if (configMatch) {
            const { title, link } = configMatch

            const preparedTitle = title ?? breadcrumbTitle ?? ''

            const linkParts = [...parts.slice(0, i), link ?? part]
            const preparedLink = `/${linkParts.join('/')}`

            breadcrumbs.push({
                title: preparedTitle,
                link: preparedLink,
            })

            if (configMatch.children) {
                currentConfig = configMatch.children
            } else {
                break
            }
        }
    }

    return breadcrumbs
}

export function useBreadcrumbs(routerPath) {
    const { breadcrumbTitle } = useNavigationContext()
    const breadcrumbs = getBreadcrumbs(routerPath, breadcrumbTitle)

    return breadcrumbs
}
