import classNames from 'classnames'
import Image from 'next/image'
import { toSvg } from 'jdenticon'

import styles from './logoImage.module.scss'

export const LogoImage = ({
    className = '',
    src = null,
    alt = '',
    // use slug to generate a placeholder
    slug = '',
    size = 27,
    ...nextImageProps
}) => {
    return (
        <div className={classNames(styles.logoImage, className)} style={{ '--size': `${size}px` }}>
            {src ? (
                <Image
                    src={src}
                    alt={alt || slug}
                    fill
                    sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                    {...nextImageProps}
                />
            ) : (
                <span
                    className={styles.generatedIcon}
                    dangerouslySetInnerHTML={{
                        __html: toSvg(slug, size, {
                            hues: [30, 210, 240],
                            saturation: {
                                color: 0.7,
                            },
                            padding: 0,
                        }),
                    }}
                />
            )}
        </div>
    )
}
