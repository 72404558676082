import { DynamicBridgeWidget } from '@dynamic-labs/sdk-react-core'
import { useRouterPages } from 'components/navbar/hooks'
import dynamic from 'next/dynamic'
import { MobileButtons } from './mobileButtons'

const WalletConnectDropdown = dynamic(
    () => import('components/account/walletConnectDropdown').then(mod => mod.WalletConnectDropdown),
    {
        ssr: false,
    }
)

const ChainSelector = dynamic(() => import('components/header/chainSelector').then(mod => mod.ChainSelector), {
    ssr: false,
})

export function HeaderButtons() {
    const { isStakeAppPage, isSwapAppPage, isPortfolioDashboard, isPortfolioLandingPage, isStarknet, isEthChain } =
        useRouterPages()

    const renderWalletConnectDropdown =
        isStakeAppPage || isSwapAppPage || isPortfolioDashboard || isPortfolioLandingPage

    const renderBridgeWidget = renderWalletConnectDropdown && isStarknet && isEthChain

    return (
        <div className='flex gap-8 items-center'>
            <MobileButtons />
            {isPortfolioDashboard ? <ChainSelector /> : null}
            {renderWalletConnectDropdown ? (
                renderBridgeWidget ? (
                    <>
                        <DynamicBridgeWidget />
                    </>
                ) : (
                    <WalletConnectDropdown />
                )
            ) : null}
        </div>
    )
}
