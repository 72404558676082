export const getGoogleTag = () => {
    if (typeof window !== 'undefined' && window?.googletag) {
        const googletag = window?.googletag || {}
        googletag.cmd = googletag?.cmd || []
        return googletag
    }

    return null
}

export const isTestAd = process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
const GOOGLE_ADS_NETWORK_ID = 22178216465

export const SlotId = {
    FloatingBanner: `${GOOGLE_ADS_NETWORK_ID}/${isTestAd ? 'test-' : ''}header-banner-full-width`,

    FooterBanner: `${GOOGLE_ADS_NETWORK_ID}/${
        isTestAd ? 'test-' : ''
    }homepage-banner/${isTestAd ? 'test-' : ''}footer-banner`,

    BuyButton: `${GOOGLE_ADS_NETWORK_ID}/${isTestAd ? 'test-' : ''}buy-button`,

    StakeButton: `${GOOGLE_ADS_NETWORK_ID}/${isTestAd ? 'test-' : ''}stake-button`,

    NativeText: `${GOOGLE_ADS_NETWORK_ID}/${isTestAd ? 'test-' : ''}native-text-ad`,
}

export const ElementId = {
    FloatingBanner: `header-banner-full-width${isTestAd ? '-test' : ''}`,
    FooterBanner: `footer-banner-homepage${isTestAd ? '-test' : ''}`,
}
