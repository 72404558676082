import { useState } from 'react'
import { useEffectOnceOnMount } from 'hooks'
import { fetchCmsContent } from 'data/cmsQueries'
import Image from 'next/image'
import { SubscribeForm } from 'components/forms'
import { MailerLiteGroupId } from 'utils/constants'
import { GTMEvent } from 'utils/GTM'

import styles from './subscribeModal.module.scss'

const trustedByQuery = `
    {
        content: pageBy(uri: "staking-data-api") {
            apiPage {
                trustedBy {
                    sourceUrl
                }
            }
        }
    }
`

export const SubscribeSection = () => {
    const [trustedBy, setTrustedBy] = useState([])

    useEffectOnceOnMount(() => {
        async function fetchAndSetCmsContent() {
            const result = await fetchCmsContent(trustedByQuery)
            setTrustedBy(result?.content?.apiPage?.trustedBy ?? [])
        }
        fetchAndSetCmsContent()
    })

    return (
        <div className={styles.container}>
            <div className={styles.header}>Get Smarter About Staking</div>
            <div className={styles.text}>Join the 75k+ investors already subscribed to the Staking Insider.</div>
            <div className={styles.formContainer}>
                <SubscribeForm
                    className={styles.formWrapper}
                    formClassName={styles.form}
                    errorClassName={styles.error}
                    groupId={MailerLiteGroupId.StakingInsider}
                    event={GTMEvent.StakingInsiderSubscribed}
                    eventFrom='topNav'
                />
            </div>

            <div className={styles.footerLine}>Read by top executives from</div>
            <div className={styles.companies}>
                {trustedBy?.map((el, i) => (
                    <Image key={i} src={el.sourceUrl} alt='Trusted by Industry Leaders' width={100} height={18} />
                ))}
            </div>
        </div>
    )
}
