import { useRef, useState, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import numeral from 'numeral'
import { useClickOutside } from 'hooks'
import { Tooltip } from 'components/ui'
import { timeObjectToDays } from 'utils/converter'
import { EMPTY_STAKING_TIME_OBJECT, isInvalidNumber } from 'utils/formatter'
import { getLimitForUnit } from './utils'

import styles from './timeDurationInput.module.scss'

const TimeUnitInput = ({
    value = '',
    onValueChange = () => {},
    minValue = '',
    maxValue = '',
    symbol = '',
    placeholder = '',
    minLimit = '',
    maxLimit = '',
    minErrorMessage = '',
    maxErrorMessage = '',
    maxInputLength = 2,
    onValidationError = () => {},
}) => {
    const inputRef = useRef(null)

    const [inputValue, setInputValue] = useState(value)
    const [isEditing, setIsEditing] = useState(false)

    const limitedMinValue = Math.ceil(Math.min(minLimit, minValue))
    const limitedMaxValue = Math.floor(Math.min(maxLimit, maxValue))

    useEffect(() => {
        setInputValue(value)
    }, [value])

    const onSetValueOnChange = useCallback(
        (newValue = '') => {
            // Validation while typing
            const newInputValue = !isInvalidNumber(newValue) ? numeral(newValue).value() : ''
            // Allow the empty string to be saved
            if (newInputValue === '') {
                setInputValue('')
                onValueChange('')
                // Do not set the value if it exceeds the maximum limit,
                // neither to input nor to state
            } else if (newInputValue > maxLimit) {
                onValidationError(maxErrorMessage)
            } else if (newInputValue < minLimit) {
                onValidationError(minErrorMessage)
            } else {
                if (newInputValue > maxValue || newInputValue < minValue) {
                    // ignore
                } else {
                    setInputValue(newInputValue)
                    if (newInputValue >= minValue) {
                        onValueChange(newInputValue)
                        onValidationError('')
                    }
                }
            }
        },
        [maxErrorMessage, maxLimit, maxValue, minErrorMessage, minLimit, minValue, onValidationError, onValueChange]
    )

    const onSetValueOnSubmit = useCallback(
        (submittedValue = '') => {
            const newInputValue = !isInvalidNumber(submittedValue) ? numeral(submittedValue).value() : ''
            if (newInputValue === '') {
                setInputValue('')
                onValueChange('')
            } else if (newInputValue > limitedMaxValue) {
                setInputValue(limitedMaxValue)
                onValueChange(limitedMaxValue)
            } else if (newInputValue < limitedMinValue) {
                setInputValue(limitedMinValue)
                onValueChange(limitedMinValue)
            } else {
                setInputValue(newInputValue)
                onValueChange(newInputValue)
            }
            onValidationError('')
        },
        [limitedMaxValue, limitedMinValue, onValidationError, onValueChange]
    )

    useClickOutside(inputRef, () => {
        if (isEditing) {
            setIsEditing(false)
        }
    })

    return (
        <div className={classNames(styles.numericInput)}>
            <input
                tabIndex={1}
                ref={inputRef}
                value={inputValue}
                type='number'
                inputMode='numeric'
                pattern='[0-9]*'
                step={1}
                title=''
                min={limitedMinValue}
                max={limitedMaxValue}
                placeholder={placeholder}
                onClick={() => setIsEditing(true)}
                onWheel={e => e.target.blur()}
                onChange={e => {
                    onSetValueOnChange(e.target.value)
                }}
                onBlur={e => {
                    // Hovering the chart steals the focus from the input.
                    // Force keeping the focus on the input if still typing.
                    if (isEditing) {
                        e.target.focus()
                    } else {
                        setIsEditing(false)
                        onSetValueOnSubmit(inputValue)
                    }
                }}
                onKeyDown={e => {
                    const key = String(e.key)
                    const characterOrSpace = key.length === 1 && /[^\r\n\t]/.test(key)
                    if (
                        key === 'e' ||
                        key === '.' ||
                        key === ',' ||
                        (minValue >= 0 && key === '-') ||
                        (characterOrSpace && /[^0-9]/.test(key)) ||
                        (String(e.target.value).length >= maxInputLength && characterOrSpace)
                    ) {
                        e.preventDefault()
                    }
                }}
            />
            {symbol && <span className={styles.withSymbol}>{symbol}</span>}
        </div>
    )
}

export const TimeDurationInput = ({
    className = '',
    value = EMPTY_STAKING_TIME_OBJECT,
    onValueChange = () => {},
    minValue = EMPTY_STAKING_TIME_OBJECT,
    maxValue = EMPTY_STAKING_TIME_OBJECT,
    showValidationError = true,
    minErrorMessage = '',
    maxErrorMessage = '',
}) => {
    const inputRef = useRef(null)
    const [timeDuration, setTimeDuration] = useState(value)
    const [validationError, setValidationError] = useState('')

    useEffect(() => {
        setTimeDuration(value)
    }, [value])

    return (
        <div className={classNames(styles.timeDurationInput, className)}>
            <Tooltip textClassName={styles.tooltip} text={showValidationError ? validationError : ''} hasInputTarget>
                <div
                    ref={inputRef}
                    className={classNames(styles.timeDurationWrap, {
                        [styles.error]: validationError !== '',
                    })}
                    onBlur={() => {
                        if (
                            timeObjectToDays(timeDuration) < timeObjectToDays(minValue) &&
                            JSON.stringify(timeDuration) !== JSON.stringify(EMPTY_STAKING_TIME_OBJECT)
                        ) {
                            setValidationError(minErrorMessage)
                        }
                    }}
                >
                    <TimeUnitInput
                        value={timeDuration.years}
                        onValueChange={years => {
                            setTimeDuration({ ...timeDuration, years })
                            onValueChange({ ...timeDuration, years })
                        }}
                        minValue={0}
                        maxValue={10}
                        placeholder={JSON.stringify(timeDuration) !== JSON.stringify(EMPTY_STAKING_TIME_OBJECT) ? 0 : 1}
                        symbol='y'
                        minLimit={getLimitForUnit(timeDuration, 'years', minValue)}
                        maxLimit={getLimitForUnit(timeDuration, 'years', maxValue)}
                        minErrorMessage={minErrorMessage}
                        maxErrorMessage={maxErrorMessage}
                        onValidationError={setValidationError}
                    />
                    <TimeUnitInput
                        value={timeDuration.months}
                        onValueChange={months => {
                            setTimeDuration({ ...timeDuration, months })
                            onValueChange({ ...timeDuration, months })
                        }}
                        minValue={0}
                        maxValue={99}
                        placeholder={0}
                        symbol='m'
                        minLimit={getLimitForUnit(timeDuration, 'months', minValue)}
                        maxLimit={getLimitForUnit(timeDuration, 'months', maxValue)}
                        minErrorMessage={minErrorMessage}
                        maxErrorMessage={maxErrorMessage}
                        onValidationError={setValidationError}
                    />
                    <TimeUnitInput
                        value={timeDuration.days}
                        onValueChange={days => {
                            setTimeDuration({ ...timeDuration, days })
                            onValueChange({ ...timeDuration, days })
                        }}
                        minValue={0}
                        maxValue={99}
                        placeholder={0}
                        symbol='d'
                        minLimit={getLimitForUnit(timeDuration, 'days', minValue)}
                        maxLimit={getLimitForUnit(timeDuration, 'days', maxValue)}
                        minErrorMessage={minErrorMessage}
                        maxErrorMessage={maxErrorMessage}
                        onValidationError={setValidationError}
                    />
                </div>
            </Tooltip>
        </div>
    )
}
