/**
 * Renders a metric box component used in reward option table rows and cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the metric box. At the top.
 * @param {string} props.value - The value of the metric box. At the bottom.
 * @param {string} props.change - The change value of the metric box. At the bottom right.
 * @param {string} props.className - Additional CSS class names for the component.
 * @param {boolean} props.isSmall - Determines if the metric box should be rendered in a small size.
 * @returns {JSX.Element} The rendered metric box component.
 */
import classNames from 'classnames'
import { getPercentageTextColorClassNames } from 'components/metrics/utils'

const getMetricBoxValue = value => {
    const isValidString = typeof value === 'string' && value !== ''
    const isValidNumber = typeof value === 'number'

    if (isValidString || isValidNumber || Boolean(value)) {
        return value
    }

    if (typeof value === 'undefined') {
        return undefined
    }

    return '-'
}

export const MetricBox = ({
    title,
    value,
    change,
    className,
    isSmall,
    titleClassName,
    valueClassName,
    valueContainerClassName,
    changeClassName,
}) => {
    const changeAsNumber = Number(change?.replace('%', ''))
    return (
        <div className={classNames('font-bold flex flex-col justify-center px-4', className)}>
            <div
                className={classNames(
                    'text-contrast-3',
                    {
                        'text-xs leading-4': !isSmall,
                        'text-[10px] leading-[10px]': isSmall,
                    },
                    titleClassName
                )}
            >
                {title}
            </div>
            <div className={classNames('mt-1 leading-4', valueContainerClassName)}>
                <span
                    className={classNames(
                        'text-contrast-4 w-full',
                        {
                            'text-base leading-4 xl:text-lg': !isSmall,
                            'text-xs leading-3': isSmall,
                        },
                        valueClassName
                    )}
                >
                    {getMetricBoxValue(value)}
                </span>
                {change && (
                    <span
                        className={classNames(
                            getPercentageTextColorClassNames(changeAsNumber, true),
                            'text-xs leading-3 ml-1',
                            changeClassName
                        )}
                    >
                        {change}
                    </span>
                )}
            </div>
        </div>
    )
}
