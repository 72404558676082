import classNames from 'classnames'
import { useClickWithTimeout } from 'hooks'

import styles from './copyLinkButton.module.scss'

const initialCopyingLabel = 'Copy Link'

export const CopyLinkButton = ({ className = '', href = '', copyLinkText = initialCopyingLabel }) => {
    const { copyingMessage, handleClickCopy } = useClickWithTimeout(href, copyLinkText)
    return (
        <button className={classNames(styles.copyLinkButton, className)} onClick={handleClickCopy}>
            <span>{copyingMessage}</span>
            <span
                className={classNames(`icon icon-copy-link`, styles.icon, {
                    [styles.hidden]: copyingMessage !== copyLinkText,
                })}
            />
        </button>
    )
}
