import classNames from 'classnames'
import { useClickWithTimeout } from 'hooks'

export function CopyAddress({
    className = '',
    textClassName = '',
    address = '',
    initialText = 'Copy address',
    copiedText = 'Address Copied!',
}) {
    const { handleClickCopy, isCopied } = useClickWithTimeout(address, initialText)
    return (
        <div
            className={classNames('flex items-center gap-2 cursor-pointer h-3', className)}
            onClick={isCopied ? () => {} : handleClickCopy}
        >
            <p className={classNames('text-contrast-4 text-sm', textClassName)}>
                {isCopied ? copiedText : initialText}
            </p>
            <div className='flex items-center w-3 h-3'>
                {isCopied ? (
                    <span className='inline-block rotate-45 ml-[3px] h-[10px] w-[5px] border-b-2 border-r-2 border-solid border-green' />
                ) : (
                    <span className={classNames('icon icon-copy2 !w-3 !h-3')} />
                )}
            </div>
        </div>
    )
}
