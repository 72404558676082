import { useRouter } from 'next/router'
import { Tab } from './tab'
import { TabsContainer } from './tabsContainer'

const mapLinkToTitle = {
    '/assets': 'Assets',
    '/providers': 'Providers',
}

export function DiscoverTabs() {
    const router = useRouter()
    const path = router.asPath

    return (
        <TabsContainer>
            {Object.entries(mapLinkToTitle).map(([link, title]) => {
                const isActive = path.startsWith(link)

                return <Tab key={link} id={link} title={title} link={link} isActive={isActive} />
            })}
        </TabsContainer>
    )
}
