import { classed } from '@tw-classed/react'
import classNames from 'classnames'
import { Loader } from 'components/ui'

export const TableContainer = classed.div('flex items-stretch justify-between w-full gap-4 px-12 py-4')

/**
 * A Table component that renders a table with dynamic data and columns. It also
 * handles loading state and displays a custom message when there are no items.
 *
 * @component
 * @param {Array<Object>} [props.data=[]] - Array of data items to be displayed in the table.
 *  * @param {Array<Object>} [props.columns=[]] - Array of column definitions. Each column object should include the following properties:
 *   - `label` {string}: The display name of the column.
 *   - `className` {string}: Optional class names for styling the column header and cells.
 *   - `width` {number}: Optional width of the column in pixels.
 *   - `render` {function}: A function that renders the content for each cell in the column. It receives the data item (row) as an argument and should return JSX to display in the cell.
 * @param {React.ReactNode} [props.children=null] - Optional content to be rendered after the table rows.
 * @param {boolean} [props.isLoading=false] - Whether the table is in a loading state. Shows a loader when true.
 * @param {string} [props.noItemsMessage='No data available'] - Message to display when there are no items and `isLoading` is false.
 * @param {boolean} [props.showMessage=false] - Controls whether the noItemsMessage is shown even when there are items.
 *
 * @returns {JSX.Element} A table displaying data in rows with dynamic columns, and showing loading or empty states.
 */
export const Table = ({
    isLava = false,
    data = [],
    columns = [],
    children = null,
    isLoading = false,
    noItemsMessage = 'No data available',
    showMessage = false,
    showHeader = true,
}) => {
    const hasNoItems = (!data?.length || !columns?.length) && !isLoading

    return (
        <div
            className={classNames('flex flex-col divide-y divide-solid divide-contrast-1', {
                'rounded-2xl border border-solid border-contrast-1 bg-white/50 dark:bg-shade-darkest': isLava,
            })}
        >
            {' '}
            {showHeader && (
                <div
                    className={classNames(
                        'flex items-stretch justify-between w-full gap-2 md:gap-4 px-2 sm:px-12 py-3',
                        {
                            'rounded-t-2xl rounded-b-none dark:bg-contrast-1 bg-white': isLava,
                        }
                    )}
                >
                    {columns?.map((column, index) => {
                        if (!column) return null
                        return (
                            <div
                                key={index}
                                className={classNames(
                                    'flex text-xs sm:text-sm font-normal text-contrast-3',
                                    !column?.width && 'basis-full',
                                    column?.className
                                )}
                                style={{
                                    minWidth: column?.width > 0 && `${column?.width}px`,
                                }}
                            >
                                {column?.label}
                            </div>
                        )
                    })}
                </div>
            )}
            {(hasNoItems || showMessage) &&
                (typeof noItemsMessage === 'string' ? (
                    <div className='px-6 py-4 text-base font-normal text-left'>{noItemsMessage}</div>
                ) : (
                    noItemsMessage
                ))}
            {isLoading ? (
                <div className='flex items-center justify-center py-4 w-full h-[200px]'>
                    <Loader />
                </div>
            ) : (
                <>
                    {data?.length > 0 && (
                        <>
                            <div className='flex flex-col divide-y divide-solid divide-contrast-1 bg-white/50 dark:bg-black/50'>
                                {data?.map((item, dataIndex) => (
                                    <div key={dataIndex}>
                                        <div className='flex items-stretch justify-between w-full gap-2 md:gap-4 px-2 sm:px-12 py-4'>
                                            {columns.map((column, cellIndex) => {
                                                if (!column) return null
                                                return (
                                                    <div
                                                        key={`${dataIndex}-${cellIndex}`}
                                                        className={classNames(
                                                            !column?.width && 'basis-full',
                                                            column?.className
                                                        )}
                                                        style={{
                                                            minWidth:
                                                                column?.width > 0 ? `${column?.width}px` : undefined,
                                                        }}
                                                    >
                                                        {column?.render?.(item) ?? null}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {children && <div className='border-solid border-y border-contrast-1'>{children}</div>}
                </>
            )}
        </div>
    )
}
