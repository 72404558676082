import classNames from 'classnames'
import styles from './skeleton.module.scss'

export const SKELETON_BG_CONTRAST1 = 'contrast1'
export const SKELETON_BG_CONTRAST2 = 'contrast2'

export const Skeleton = ({ width = '100%', height = '100%', borderRadius = 0, variant = SKELETON_BG_CONTRAST1 }) => {
    return (
        <span
            className={classNames(styles.skeleton, {
                [styles.contrast1]: variant === SKELETON_BG_CONTRAST1,
                [styles.contrast2]: variant === SKELETON_BG_CONTRAST2,
            })}
            style={{ width, height, borderRadius }}
        />
    )
}

export const withLoadingSkeleton = Component => {
    return function ComponentWithLoadingSkeletonWrapper(props) {
        const isLoading = Boolean(props?.isLoading)

        if (isLoading) {
            const width = props?.width ?? '100%'
            const height = props?.height ?? '100%'
            const borderRadius = props?.borderRadius ?? '8px'
            const skeletonBg = props?.skeletonBg ?? SKELETON_BG_CONTRAST1
            return <Skeleton width={width} height={height} borderRadius={borderRadius} variant={skeletonBg} />
        }

        return <Component {...props} />
    }
}
