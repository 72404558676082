import classNames from 'classnames'
import Image from 'next/image'
import { useMediaPredicate } from 'react-media-hook'

import { LogoImage } from 'components/ui/logoImage'

import styles from './itemWithLogo.module.scss'
import { useRouter } from 'next/router'
import { THEME, useTheme } from 'state/storage'
import { Skeleton, SKELETON_BG_CONTRAST2 } from 'components/ui/skeleton'
import { TooltipOnHover } from 'components/ui/tooltip'

export const DETAILS_TYPE_STATUS_ACTIVE = 'statusActive'
export const DETAILS_TYPE_STATUS_INACTIVE = 'statusInactive'
export const DETAILS_TYPE_TEXT = 'text'

export const StatusLabel = {
    Active: 'Active',
    Standby: 'Standby',
    Inactive: 'Inactive',
    None: 'None',
}

export const GroupSize = {
    Small: 'small',
    Medium: 'larger',
    Large: 'large',
}

const LoadingItemWithLogo = ({
    className = '',
    iconSize = 36,
    sizeType = GroupSize.Small,
    withDetails = false,
    reverseDetailsOrder = false,
}) => {
    const isTablet = useMediaPredicate('(min-width: 768px)')

    return (
        <div className={classNames(styles.itemWithLogo, className)}>
            <div className={styles.imgWithIconWrap}>
                <Skeleton width={iconSize} height={iconSize} borderRadius={'50%'} variant={SKELETON_BG_CONTRAST2} />
            </div>
            <div
                className={classNames(styles.detailsWrap, {
                    [styles.reverse]: reverseDetailsOrder,
                    [styles.withDetails]: isTablet && withDetails,
                })}
            >
                <Skeleton
                    height={sizeType === GroupSize.Large ? '20px' : sizeType === GroupSize.Medium ? '16px' : '14px'}
                    width={'60px'}
                    variant={SKELETON_BG_CONTRAST2}
                    borderRadius={'4px'}
                />
                {isTablet && withDetails && (
                    <Skeleton
                        height={sizeType === GroupSize.Large ? '18px' : sizeType === GroupSize.Medium ? '14px' : '13px'}
                        width={'50px'}
                        variant={SKELETON_BG_CONTRAST2}
                        borderRadius={'4px'}
                    />
                )}
            </div>
        </div>
    )
}

export const ItemWithLogo = ({
    className = '',
    name = '',
    iconUrl = '',
    details = '',
    detailsType = DETAILS_TYPE_TEXT,
    slug = '',
    alt = '',
    isVerified = false,
    iconSize = 36,
    sizeType = GroupSize.Small,
    isLoading = false,
    // Specify whether details are expected for showing the skeleton
    withDetails = false,
    // Show details (e.g. ticker) first
    reverseDetailsOrder = false,
    isPro = false,
}) => {
    const theme = useTheme()
    const fileName = theme === THEME.DARK ? 'vsp-pro-dark' : 'vsp-pro-light'
    if (isLoading) {
        return (
            <LoadingItemWithLogo
                iconSize={iconSize}
                withDetails={withDetails}
                sizeType={sizeType}
                reverseDetailsOrder={reverseDetailsOrder}
            />
        )
    }

    return (
        <div
            className={classNames(
                styles.itemWithLogo,
                {
                    [styles.medium]: sizeType === GroupSize.Medium,
                    [styles.large]: sizeType === GroupSize.Large,
                },
                className
            )}
        >
            <div className={styles.imgWithIconWrap}>
                <LogoImage src={iconUrl} alt={alt || name} slug={slug} size={iconSize} />
                {isVerified && (
                    <div className={styles.verifiedWrap}>
                        <Image
                            src={isPro ? `/static/svg/${fileName}.svg` : '/static/svg/vsp-tag.svg'}
                            alt={`Verified Provider`}
                            width={iconSize > 40 ? 20 : 18}
                            height={iconSize > 40 ? 20 : 18}
                            priority
                        />
                    </div>
                )}
            </div>
            <div
                className={classNames(styles.detailsWrap, {
                    [styles.reverse]: reverseDetailsOrder,
                    [styles.withDetails]: Boolean(details) && name,
                })}
            >
                <span className={styles.name}>{name}</span>
                {Boolean(details) ? (
                    <span
                        className={classNames(styles.details, {
                            [styles.active]: detailsType === DETAILS_TYPE_STATUS_ACTIVE,
                            [styles.inactive]: detailsType === DETAILS_TYPE_STATUS_INACTIVE,
                            [styles.asName]: !name,
                        })}
                    >
                        {details}
                    </span>
                ) : null}
            </div>
        </div>
    )
}

export const RewardOptionItemWithLogo = ({
    className = '',
    name = '',
    iconUrl = '',
    details = '',
    status = StatusLabel.None,
    slug = '',
    alt = '',
    iconSize = 36,
    sizeType = GroupSize.Small,
    isLoading = false,
    // Specify whether details are expected for showing the skeleton
    withDetails = false,
    operator,
    hideName = false,
}) => {
    const router = useRouter()
    if (isLoading) {
        return <LoadingItemWithLogo iconSize={iconSize} withDetails={withDetails} sizeType={sizeType} />
    }
    return (
        <div
            className={classNames(
                styles.itemWithLogo,
                {
                    [styles.medium]: sizeType === GroupSize.Medium,
                    [styles.large]: sizeType === GroupSize.Large,
                },
                className
            )}
            onClick={e => {
                if (!operator) return
                e.preventDefault()
                e.stopPropagation()
                router.push(`/provider/${slug}`)
            }}
        >
            <div className={styles.imgWithIconWrap}>
                <LogoImage src={iconUrl} alt={alt || name} slug={slug} size={iconSize} />
                {status !== StatusLabel.None && !operator && (
                    <TooltipOnHover text={status} textClassName={styles.tooltip}>
                        <div className={styles.statusWrap}>
                            <div
                                className={classNames(styles.circle, {
                                    [styles.active]: status === StatusLabel.Active,
                                    [styles.inactive]:
                                        status === StatusLabel.Standby || status === StatusLabel.Inactive,
                                })}
                            />
                        </div>
                    </TooltipOnHover>
                )}
                {operator && (
                    <div
                        className={styles.operatorWrap}
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            router.push(`/provider/${operator.slug}`)
                        }}
                    >
                        <div className={styles.circle}>
                            <LogoImage src={operator.logoUrl} alt={operator.name} slug={operator.slug} size={28} />
                        </div>
                    </div>
                )}
            </div>
            <div
                className={classNames(styles.detailsWrap, {
                    [styles.withDetails]: Boolean(details) && name,
                })}
            >
                {!hideName && <span className={styles.name}>{name}</span>}
                <span className={styles.details}>{details}</span>
            </div>
        </div>
    )
}
