import classNames from 'classnames'
import { UNDER_MAINTENANCE, TRENDING } from 'utils/constants'
import { Link, withTooltip } from 'components/ui'

import styles from './tag.module.scss'

export const Tag = ({
    className = '',
    label = 'Tag',
    tooltipTextObj = { title: '', text: '' },
    minimal = true,
    link = '',
}) => {
    const innerTag = (
        <div
            className={classNames(styles.tag, {
                [styles.yellow]: label === UNDER_MAINTENANCE,
                [styles.minimal]: minimal,
            })}
        >
            {label === TRENDING && <span className={classNames(`icon icon-og icon-flame`, styles.icon)} />}
            <span className={styles.label}>{label}</span>
        </div>
    )

    const TagWithTooltip = withTooltip(() => {
        return <div className={styles.label}>{link ? <Link href={link}>{innerTag}</Link> : innerTag}</div>
    })

    return (
        <div
            className={classNames(
                {
                    ['hover:opacity-80']: link,
                },
                className
            )}
        >
            <TagWithTooltip tooltipObject={tooltipTextObj} tooltipClassName={styles.tooltip} />
        </div>
    )
}
