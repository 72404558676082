import { useSharedImageAdState } from 'components/ads/hooks'
import React, { createContext, useContext } from 'react'

const FloatingBannerContext = createContext()

export const FloatingBannerProvider = ({ children }) => {
    const { imageAdState } = useSharedImageAdState()

    return (
        <FloatingBannerContext.Provider value={{ floatingBannerState: imageAdState }}>
            {children}
        </FloatingBannerContext.Provider>
    )
}

export const useFloatingBanner = () => useContext(FloatingBannerContext)
