import Script from 'next/script'
import { useEffect } from 'react'

export const ClarityTracking = ({ clarityTagId = 'az1syvg98s' }) => {
    return (
        <Script
            id='clarity-tracking'
            strategy='lazyOnload'
            dangerouslySetInnerHTML={{
                __html: `
                    (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "${clarityTagId}");
                `,
            }}
        />
    )
}

export const GoogleTagManager = ({ trackingId = 'GTM-K6XVG7F' }) => (
    <>
        <Script
            id='gtm'
            async
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
                __html: `<!-- Google Tag Manager -->
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://api.stakingrewards.com/proxy/script/'+i;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${trackingId}');
                    <!-- End Google Tag Manager -->
                `,
            }}
        />
        <noscript
            dangerouslySetInnerHTML={{
                __html: `<iframe src="https://api.stakingrewards.com/metrics/ns.html?id=${trackingId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
        />
    </>
)

export const HubSpotTracking = ({ hubSpotId = '48107031' }) => {
    return (
        <Script id='hs-script-loader' src={`//js.hs-scripts.com/${hubSpotId}.js`} strategy='lazyOnload' async defer />
    )
}

export const OptinMonsterTracker = () => {
    return (
        <Script
            id='optin-monster'
            strategy='lazyOnload'
            dangerouslySetInnerHTML={{
                __html: `
                    (function(d,u,ac){
                        var s=d.createElement('script');s.type='text/javascript';
                        s.src='https://a.omappapi.com/app/js/api.min.js';
                        s.async=true;s.dataset.user=u;s.dataset.account=ac;
                        d.getElementsByTagName('head')[0].appendChild(s);
                    })(document,263323,280846);
                `,
            }}
        />
    )
}

export const SafaryTracking = () => {
    return <Script id='safary' strategy='lazyOnload' src='https://tag.safary.club/stag.js?id=prd_p5zDsCO22j' />
}

export const RelicMonitoring = () => {
    const environmentId = process.env.NEXT_PUBLIC_RELIC_ENV_ID
    const samplingRate = process.env.NEXT_PUBLIC_RELIC_SAMPLING_RATE
    const errorSamplingRate = process.env.NEXT_PUBLIC_RELIC_ERROR_SAMPLING_RATE

    useEffect(() => {
        window.relicEnvironmentId = environmentId
        window.relicSamplingRate = samplingRate
        window.relicErrorSamplingRate = errorSamplingRate
    }, [environmentId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Script
            id='relic-monitoring'
            async
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
                __html: `
                    var s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'new-relic-min.js';
                    document.getElementsByTagName('head')[0].appendChild(s);
                `,
            }}
        />
    )
}
