import { useState } from 'react'
import classNames from 'classnames'
import { useTheme } from 'state'
import { validateEmail } from 'utils/actions'
import { MailerLiteGroupId } from 'utils/constants'

import styles from './subscribeForm.module.scss'
import { logEvent } from 'utils/GTM'

export const SUBSCRIPTION_UNDER_MAINTENANCE = 'asset_maintenance'
export const SUBSCRIPTION_STAKING_INSIDER = 'staking_insider'
export const SUBSCRIPTION_SRETH = 'sreth_early_access'

const successMessage = 'You’re almost there! Check your inbox and confirm your email to complete your subscription.'

export const SubscribeForm = ({
    className = '',
    formClassName = '',
    errorClassName = '',
    groupId = MailerLiteGroupId.StakingInsider,
    event,
    eventFrom,
    asset,
}) => {
    const theme = useTheme()

    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()
        if (event) {
            logEvent(event, {
                signup_location: eventFrom,
            })
        }
        const email = e.target?.elements?.email?.value
        const validated = validateEmail(email)

        if (!validated) {
            setError('Please enter a valid email.')
            setSending(false)
        } else {
            setError(null)
            setSending(true)

            const data = await fetch('/api/subscribe', {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    groups: [groupId],
                    ...(groupId === MailerLiteGroupId.Maintenance && {
                        fields: {
                            maintenance_asset: asset?.slug,
                        },
                    }),
                }),
            })

            const body = await data.json()

            if (data.status !== 200) {
                setError(body?.message)
                setSending(false)
            } else {
                setSending(false)
                setSuccess(true)
            }
        }
    }

    return (
        <div
            className={classNames(styles.subscribeForm, className)}
            data-sending={sending}
            data-success={success}
            data-error={!!error}
            data-theme={theme}
        >
            <form onSubmit={handleSubmit} className={formClassName}>
                <input
                    type='email'
                    name='email'
                    placeholder='Enter your email.'
                    required
                    onChange={() => {
                        if (error) {
                            setError(null)
                        }
                    }}
                />
                <button type='submit'>Subscribe</button>
            </form>

            {sending && (
                <div className={styles.sending}>
                    <span className='loader' />
                </div>
            )}

            {error && <div className={classNames(styles.error, errorClassName)}>{error}</div>}

            {success && <div className={styles.success}>{successMessage}</div>}
        </div>
    )
}
