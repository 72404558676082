import classNames from 'classnames'
import { HomeCard } from 'components/ui/homeCard'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import styles from './hero.module.scss'

export function Hero() {
    const stakableAssets = ['ETH', 'ATOM', 'TIA', 'DYDX', 'LAVA', 'BTC']

    const [currentAssetIndex, setCurrentAssetIndex] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentAssetIndex(prevIndex => (prevIndex + 1) % stakableAssets.length)
        }, 2500)

        return () => clearInterval(interval)
    }, [stakableAssets.length])

    const HOME_CARD_LINKS = [
        {
            title: 'Discover',
            description: '100+ stakeable assets and 70+ verified staking providers',
            image: '/static/svg/hero-card-1.svg',
            href: '/assets',
            logo: (
                <div>
                    <Image
                        src={'/static/svg/vsp-tag-extended.svg'}
                        alt={`Verified`}
                        width={60}
                        height={20}
                        sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                    />
                </div>
            ),
            id: 'imagea',
        },
        {
            title: 'Stake your',
            description: 'Stake across different ecosystems always at the best rate',
            image: '/static/svg/hero-image-2.svg',
            href: '/stake-app',
            logo: (
                <div
                    key={stakableAssets[currentAssetIndex]}
                    className={classNames('font-bold text-[24px] text-primary', styles.animationFadeInOut)}
                >
                    {stakableAssets[currentAssetIndex]}
                </div>
            ),
            id: 'imageb',
        },
        {
            title: 'Optimize',
            description: 'Unlock the full potential of your staked positions',
            image: '/static/svg/hero-card-3.svg',
            href: '/terminal',
            logo: <div className='text-green text-xs bg-green/10 p-2 px-3 rounded-2xl md:w-auto'>Alpha</div>,
            id: 'imagec',
        },
    ]

    return (
        <>
            <div className='flex flex-col items-center mb-[64px] mt-[64px] sm:mt-[80px] sm:mb-[80px] lg:mb-[96px] lg:mt-[96px] '>
                <h1 className='text-center mb-[8px] sm:text-[48px]'>Secure & Reliable Crypto Staking</h1>

                <p className='text-center text-[16px] sm:text-[24px] text-gray-700 font-normal'>
                    Empowering 3M+ individuals & enterprises to stake with ease since 2018{' '}
                </p>
            </div>
            <div className='grid grid-cols-1 xl:grid-cols-3 gap-[32px] xl:gap-[40px] mb-[64px] sm:mb-[80px] lg:mb-[96px]'>
                {HOME_CARD_LINKS.map((card, index) => (
                    <HomeCard
                        key={index}
                        href={card.href}
                        imageSrc={card.image}
                        title={card.title}
                        description={card.description}
                        logo={card.logo}
                        id={card.id}
                    />
                ))}
            </div>
        </>
    )
}
