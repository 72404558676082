import { Link } from 'components/ui'

export function HeaderLogo() {
    return (
        <Link href='/'>
            <div className='flex flex-row items-center gap-4'>
                <div className='flex items-center w-10 h-5'>
                    <span className='icon icon-sr [--size:40px] [--color:blue] dark:[--color:--c-lightest]' />
                </div>
                <span className='font-semibold hidden md:block'>Staking Rewards</span>
            </div>
        </Link>
    )
}
