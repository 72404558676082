import { useRouter } from 'next/router'
import { DiscoverTabs } from './discoverTabs'
import { AssetTabs } from './assetTabs'
import { ProviderTabs } from './providerTabs'

const pathToTabsComponent = {
    '/assets': DiscoverTabs,
    '/providers': DiscoverTabs,
    '/asset': AssetTabs,
    '/provider': ProviderTabs,
}

export function HeaderTabs() {
    const router = useRouter()
    const path = router.asPath

    const tabsComponentKey = Object.keys(pathToTabsComponent).find(key => path.startsWith(key))

    const TabsComponent = pathToTabsComponent[tabsComponentKey]

    if (TabsComponent) {
        return <TabsComponent />
    }

    return null
}
