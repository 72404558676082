import classNames from 'classnames'

import styles from './titleDescription.module.scss'

export const TitleDescription = ({ className = '', large = false, children }) => {
    return (
        <p
            className={classNames(styles.titleDescription, { [styles.large]: large }, className)}
            data-cy='cy-description'
        >
            {children}
        </p>
    )
}
