export const PRODUCT_LINKS = [
    {
        title: 'Jobs',
        link: 'https://staking-rewards.breezy.hr/',
        blank: true,
    },
    {
        title: 'VSP',
        link: '/vsp',
        blank: false,
    },
]

export const ENTERPRISE_LINKS = [
    {
        title: 'Institutional Investors',
        link: 'https://share.hsforms.com/1jvR-ve9oR4SOtwfrQTVXLAsn3mf',
        blank: true,
    },
    {
        title: 'Sponsor the Summit',
        link: 'https://www.stakingsummit.com/sponsor',
        blank: true,
    },
    {
        title: 'Get Your Asset Integrated',
        link: 'https://share.hsforms.com/1TjnH2ggMS2ChI5Wwj5O_igsn3mf',
        blank: true,
    },
    {
        title: 'Advertise',
        link: 'https://share.hsforms.com/1DoxtVdJZQNmwuxv7sLBXawsn3mf',
        blank: true,
    },
    {
        title: 'Staking Data API',
        link: 'https://share.hsforms.com/1r7SP8Y4LTnCK7mKolKQlAQsn3mf',
        blank: true,
    },
    {
        title: 'Get Verified',
        link: 'https://share.hsforms.com/18Glp4LdwQUam6aXck6_Ujgsn3mf',
        blank: true,
    },
    {
        title: 'Claim Provider Profile',
        link: 'https://share.hsforms.com/1FN3sO46RRwKDtEJNYRHjCwsn3mf',
        blank: true,
    },
]

export const LEGAL_LINKS = [
    {
        title: 'Disclaimer',
        link: '/disclaimer',
        blank: false,
    },
    {
        title: 'Privacy Policy',
        link: '/privacy-policy',
        blank: false,
    },
    {
        title: 'Terms of Service',
        link: '/terms-and-conditions',
        blank: false,
    },
    {
        title: 'AML Policy',
        link: '/aml-policy',
        blank: false,
    },
]
